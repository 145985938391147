import React from "react";
import {
	Button,
	Col,
	Collapse,
	Modal,
	ModalBody,
	ModalHeader,
	Nav,
	NavbarToggler,
	NavItem,
	NavLink
} from "shards-react";
import axios from "axios";

const R = require("ramda");

const isMobile = () => {
	const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

	return toMatch.some((toMatchItem) => {
		return navigator.userAgent.match(toMatchItem);
	});
};

class SideBar extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			curvedCollapseOpen: !isMobile(),
			balances: [],
			modal_token_actions: false,
			modal_token_order: false,
			tokens: [],
		};
	}

	componentDidMount() {
		this.getWallet();

		window.onload = () => {
			const root = document.getElementById("root");
			const sidebar = document.getElementById("main-sidebar");

			if (!isMobile()) {
				sidebar.style.height = document.body.offsetHeight;
			} else {
				sidebar.style.height = "";
			}

			window.onresize = () => {
				if (!isMobile()) {
					sidebar.style.height = document.body.offsetHeight;
				} else {
					sidebar.style.height = "";
				}
			};
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const cAddress = this.props.address;
		const pAddress = prevProps.address;

		if (pAddress !== cAddress) {
			this.getWallet();
		}
	}

	getWallet = () => {
		const { address } = this.props;

		if (address) {
			const wallet_url = "https://us-central1-curved-money.cloudfunctions.net/getWallet/?address=" + address;

			axios({
				method: "GET",
				headers: {
					"content-type": "text/javascript",
					"access-control-allow-origin": "*",
				},
				url: wallet_url,
			})
				.then((wallet_result) => {
					const _state = {};

					_state.balances = R.pathOr(null, ["data", "balances"], wallet_result);
					_state.tokens = R.pathOr(null, ["data", "tokens"], wallet_result);

					this.setState({
						...this.state,
						..._state,
					});
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			console.error("[ERROR] - Account address not found.");
		}
	};

	handleModal = (event, modal) => {
		const _state = {};

		_state.modal_token_actions = this.state.modal_token_actions ? !this.state.modal_token_actions : modal === 1;

		this.setState({
			...this.state,
			..._state,
		});
	};

	handleTokenActions = (token) => {
		const { tokens } = this.props;
		const { address } = token;
		const isApproved = R.pathEq(["approved"], true);
		const isToken = R.pathEq(["address"], address);
		const approvedToken = R.filter(R.both(isApproved, isToken), tokens);

		const _state = {};

		_state.modal_token_actions = true;
		_state.token = {
			...token,
			approved: !R.isEmpty(approvedToken),
		};

		this.setState({
			...this.state,
			..._state,
		});
	};

	renderMenuWallet = () => {
		const { balances, tokens } = this.state;

		return (
			<React.Fragment>
				<Nav navbar vertical className="menu-wallet">
					{balances.sort(function(a, b) {
						const a_bal_uint = Math.pow(10, R.pathOr(0, ["decimals"], tokens[a.address]));
						const b_bal_uint = Math.pow(10, R.pathOr(0, ["decimals"], tokens[b.address]));

						return  (b.balance / b_bal_uint) - (a.balance / a_bal_uint);
					}).map((balance, index) => {
						const token = tokens[balance.contract];
						const address = R.pathOr("", ["address"], token);
						const name = R.pathOr(address, ["name"], token);
						const symbol = R.pathOr("", ["symbol"], token);
						const image = R.pathOr(null, ["image"], token);
						const imageUrl = image ? "https://ethplorer.io/" + image.replace("https://ethplorer.io/", "") : null;
						const decimals_uint = Math.pow(10, token.decimals);

						return (
							<NavItem key={"nav-item-" + index}>
								<NavLink className="d-flex">
									<div className="contract-image mr-2">
										{image && <img alt="" src={imageUrl} />}
										{!image && <img alt="" src="/curved.png" />}
									</div>
									<div className="contract-info w-100">
										<h6 className="mb-2 position-relative">
											<span className="token-name">
												{name.slice(0, 12)}
												{name.length > 12 ? "..." : ""}
											</span>
											<span
												className="token-action position-absolute"
												onClick={(event) => {
													this.handleTokenActions(token);
												}}>
												<img
													alt=""
													src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8dGl0bGUvPgoKIDxnPgogIDx0aXRsZT5iYWNrZ3JvdW5kPC90aXRsZT4KICA8cmVjdCBmaWxsPSJub25lIiBpZD0iY2FudmFzX2JhY2tncm91bmQiIGhlaWdodD0iNDAyIiB3aWR0aD0iNTgyIiB5PSItMSIgeD0iLTEiLz4KIDwvZz4KIDxnPgogIDx0aXRsZT5MYXllciAxPC90aXRsZT4KICA8cGF0aCBmaWxsPSIjM2Q1MTZmIiBpZD0iZWxsaXBzaXMtdmVydGljYWwtMiIgZD0ibTEyLDZhMywzIDAgMSAwIC0zLC0zYTMsMyAwIDAgMCAzLDN6bTAsM2EzLDMgMCAxIDAgMywzYTMsMyAwIDAgMCAtMywtM3ptMCw5YTMsMyAwIDEgMCAzLDNhMywzIDAgMCAwIC0zLC0zeiIvPgogPC9nPgo8L3N2Zz4="
												/>
											</span>
										</h6>
										<p className="m-0 small muted">
											<span className="token-balance d-inline-block w-100">
												{(balance.balance / decimals_uint).toFixed(6)}&nbsp;{symbol.slice(0, 12).toUpperCase()}
											</span>
										</p>
									</div>
								</NavLink>
							</NavItem>
						);
					})}
					<NavItem key="nav-item-footer" className="nav-item-footer">
						<NavLink className="d-flex" />
					</NavItem>
				</Nav>
			</React.Fragment>
		);
	};

	renderSideBar = () => {
		const { curvedCollapseOpen } = this.state;

		return (
			<Col id="main-sidebar" tag="aside" className={["main-sidebar", "px-0", "col-12", curvedCollapseOpen ? "open" : undefined].join(" ")} lg="2" md="3">
				<Nav navbar vertical>
					<NavItem>
						<NavLink href="#" className="logo-link justify-content-center align-items-center d-flex">
							<NavbarToggler
								className="p-0"
								type="a"
								onClick={() => {
									if (isMobile()) {
										this.toggleWalletNavigation();
									}
								}}>
								<h2 className="logo-title float-left my-0">wallet</h2>
							</NavbarToggler>
						</NavLink>
					</NavItem>
				</Nav>
				<Collapse className="justify-content-end" open={curvedCollapseOpen} navbar>
					{this.renderMenuWallet()}
				</Collapse>
			</Col>
		);
	};

	renderTokenActionsModal = () => {
		const { balances, modal_token_actions, token } = this.state;

		const price = R.pathOr(null, ["price", "rate"], token);
		const market_cap = R.pathOr(null, ["price", "marketCapUsd"], token);
		const available_supply = R.pathOr(null, ["price", "availableSupply"], token);
		const total_supply = R.pathOr(null, ["totalSupply"], token);
		const decimals_uint = Math.pow(10, R.pathOr(0, ["decimals"], token));
		const balance_element = R.find(R.propEq("contract", R.pathOr("", ["address"], token)))(balances);
		const balance = R.pathOr(null, ["balance"], balance_element);

		return (
			<React.Fragment>
				{modal_token_actions && (
					<Modal size="md" open={modal_token_actions} toggle={this.handleModal}>
						<ModalHeader>
							<p className="text-sm-left mb-0 p-0 position-relative">
								<span>{token.name}&nbsp;({token.symbol})</span>
							</p>
						</ModalHeader>
						<ModalBody className="position-relative">
							<div className="modal-token-info d-flex justify-content-around flex-wrap mb-3">
								<div className="d-flex flex-column">
									<div className="token-info-header">BALANCE</div>
									<div className="py-1">{balance ? (balance / decimals_uint).toFixed(6) : 0..toFixed(6)}</div>
								</div>
								<div className="d-flex flex-column">
									<div className="token-info-header">PRICE</div>
									<div className="py-1">{price ? "$" + price.toFixed(6) + " USD" : "N/A"}</div>
								</div>
								<div className="d-flex flex-column">
									<div className="token-info-header">CAPITALIZATION</div>
									<div className="py-1">{market_cap ? "$" + market_cap.toFixed(2) + " USD" : "N/A"}</div>
								</div>
								<div className="d-flex flex-column">
									<div className="token-info-header">FDV</div>
									<div className="py-1">{market_cap ? "$" + (price * (total_supply / decimals_uint)).toFixed(2) + " USD" : "N/A"}</div>
								</div>
								<div className="d-flex flex-column">
									<div className="token-info-header">AVAILABLE SUPPLY</div>
									<div className="py-1">{available_supply ? (available_supply).toFixed(2) : "N/A"}</div>
								</div>
								<div className="d-flex flex-column">
									<div className="token-info-header">TOTAL SUPPLY</div>
									<div className="py-1">{total_supply ? (total_supply / decimals_uint).toFixed(2) : "N/A"}</div>
								</div>
							</div>
							<div className="d-flex justify-content-around">
								<Button
									disabled={token.approved}
									className="approve-button w-100"
									onClick={(event) => {
										this.props
											.sendTokenApproval(token.address)
											.then((result) => {
												if (result) {
													this.handleModal();
												}
											})
											.catch((error) => {
												console.error(error);
											});
									}}>
									APPROVE
								</Button>
								<Button
									disabled={!token.approved}
									className="buy-button mx-2 w-100"
									onClick={(event) => {
										this.handleModal();
										this.props.orderModal(event, 1);
									}}>
									BUY
								</Button>
								<Button
									disabled={!token.approved}
									className="sell-button mr-2 w-100"
									onClick={(event) => {
										this.handleModal();
										this.props.orderModal(event, 2);
									}}>
									SELL
								</Button>
								<Button
									className="cancel-button w-100"
									onClick={(event) => {
										this.handleModal();
									}}>
									CANCEL
								</Button>
							</div>
						</ModalBody>
					</Modal>
				)}
			</React.Fragment>
		);
	};

	toggleWalletNavigation = () => {
		const _state = {};

		_state.curvedCollapseOpen = !this.state.curvedCollapseOpen;

		this.setState({
			...this.state,
			..._state,
		});
	};

	render() {
		return (
			<React.Fragment>
				{this.renderTokenActionsModal()}
				{this.renderSideBar()}
			</React.Fragment>
		);
	}
}

SideBar.propTypes = {};

export default SideBar;
