/* global BigInt */

import React, { Component } from "react";
import { render } from "react-dom";
import axios from "axios";
import { from, timer } from "rxjs";
import { concatMap, filter, map, take } from "rxjs/operators";
import wNumb from "wnumb";
import {
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Col,
	Container,
	FormCheckbox,
	FormGroup,
	FormInput,
	ListGroup,
	ListGroupItem,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	Slider,
} from "shards-react";
import { Dropdown } from "semantic-ui-react";
import fitty from "fitty";

import { abiERC20Token } from "./abi-erc-20-token";
import { abiUniswapV2Exchange } from "./abi-uniswap-v2-exchange";
import { abiUniswapV2Pair } from "./abi-uniswap-v2-pair";
import { abiUniswapV2Router } from "./abi-uniswap-v2-router";

import NavigationBar from "./common/elements/NavigationBar";
import SideBar from "./common/elements/SideBar";
import FooterBar from "./common/elements/FooterBar";
// import ChartContainer from "./common/elements/ChartContainer";

import "semantic-ui-css/semantic.css";
import "bootstrap/dist/css/bootstrap-reboot.css";
import "shards-ui/dist/css/shards.css";

import "./style-bootstrap.css";
import "./style-main.css";
import "./style-sidebar.css";
import "./style-navigation.css";

const { web3 } = window;

const R = require("ramda");
const sign = require("ethjs-signer").sign;
const Buffer = require("safe-buffer").Buffer;
const keccak256 = require("keccak256");
const { publicKeyConvert, publicKeyCreate } = require("secp256k1");
const { toChecksumAddress } = require("ethereum-checksum-address");

const uniswapV2Factory = "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f";
const uniswapV2Router = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";

// Mainnet values
const wethToken = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2";

// Ropsten testnet values
// const wethToken = "0xc778417e063141139fce010982780140aa0cd5ab";

const deviation = (a, b) => {
	return 100 * Math.abs((a - b) / ((a + b) / 2));
};

const isMobile = () => {
	const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

	return toMatch.some((toMatchItem) => {
		return navigator.userAgent.match(toMatchItem);
	});
};

class App extends Component {
	constructor(props) {
		super(props);

		const activities_ = JSON.parse(localStorage.getItem("activities"));
		const activities = activities_ ? activities_ : [];
		const options_ = JSON.parse(localStorage.getItem("options"));
		const options = options_ ? options_ : [];
		const orders = JSON.parse(localStorage.getItem("orders"));
		const pkm = localStorage.getItem("pkm");
		const tokens_ = JSON.parse(localStorage.getItem("tokens"));
		const tokens = tokens_ ? tokens_ : {};
		const selected_symbol = localStorage.getItem("selected_symbol");

		this.state = {
			_persistTimestamp: null,
			account: null,
			active_order: null,
			active_order_price: 0,
			activities: activities,
			balance: 0,
			base_unit: 0.00001,
			create_entry_order: true,
			create_exit_order: true,
			currency: "usd",
			custom_gas_price: 0,
			custom_gas_limit: 0,
			crypto_price: 0,
			disable_entrance: false,
			disable_exit: false,
			gas: "fastest",
			gas_limits: {},
			options: options,
			orders: orders ? orders : [],
			order_entry_amount: 0,
			order_exit_amount: 0,
			position_entry_amount: 0,
			price_data: {
				current: 0,
				low: 0,
				high: 0,
				volume: 0,
			},
			private_key: pkm ? pkm : "",
			private_key_persisted: pkm ? true : false,
			selected_symbol: selected_symbol ? selected_symbol : R.pathOr("", [Object.keys(tokens)[0], "symbol"], tokens),
			selected_symbol_balance: 0,
			slider_order_entry_value: 1,
			slider_order_exit_value: 1,
			slider_order_percentage_value: 100,
			slider_position_entry_value: 0,
			slider_position_exit_value: 0,
			slider_profit_percentage_value: 0,
			toggle_custom_entry_fee_input: false,
			toggle_custom_exit_fee_input: false,
			toggle_order_entry_label: false,
			toggle_order_entry_slider: false,
			toggle_order_exit_label: false,
			toggle_order_exit_slider: false,
			toggle_position_entry_slider: false,
			toggle_position_entry_exit_slider: false,
			toggle_position_exit_slider: false,
			token_list: true,
			tokens: tokens ? tokens : {},
			uniswap_tokens: [],
			uniswapV1: false,
			watchlist: [],
		};

		this.fit_pk_label = null;
		this.loop_price_crypto = null;
		this.loop_price_data = null;
		this.loop_price_poll = null;
	}

	componentDidMount() {
		const { orders, selected_symbol, tokens } = this.state;

		console.log("web3.version.api", web3.version.api);

		this.getCryptoPrice("ethereum")
			.then((result) => {
				const _state = {};

				_state.disable_entrance = false;

				this.setState({
					...this.state,
					..._state,
				});

				this.loop_price_crypto = setInterval(() => {
					this.getCryptoPrice("ethereum");
				}, 15e3);

				if (selected_symbol && selected_symbol !== "") {
					this.setPriceData(this.state.selected_symbol);
					this.setTokenBalance(this.state.selected_symbol);

					this.loop_price_data = setInterval(() => {
						this.setPriceData(this.state.selected_symbol);
						this.setTokenBalance(this.state.selected_symbol);
					}, 15e3);
				}

				this.refreshSignedValues();

				this.loop_price_poll = setInterval(() => {
					this.pollWatchlist();
				}, 3e3);
			})
			.catch((error) => {
				console.error(error);
			});

		this.getTokenOptions()
			.then((result) => {
				const _state = {};

				_state.options = result;

				this.setState(
					{
						...this.state,
						..._state,
					},
					() => {
						this.updatePersistedOptions();
					}
				);
			})
			.catch((error) => {
				console.error(error);
			});

		(async () => {
			const activeOrders = R.pathEq(["status"], "active");
			const matchedOrders = R.filter(activeOrders, orders);
			const watchObject = {};

			matchedOrders.forEach((order, index) => {
				watchObject[order.symbol] = 1;
			});

			const _state = {};

			_state.watchlist = Object.keys(watchObject);

			this.setState({
				...this.state,
				..._state,
			});
		})();

		if (isMobile()) {
			window.onload = () => {
				this.fit_pk_label = fitty(".pk-label");
				this.fit_pk_label[0].fit();
			};
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { selected_symbol, tokens } = this.state;

		if (prevState.selected_symbol !== this.state.selected_symbol) {
			this.refreshSignedValues();

			clearInterval(this.loop_price_data);

			if (selected_symbol && selected_symbol !== "") {
				this.setPriceData(this.state.selected_symbol);
				this.setTokenBalance(this.state.selected_symbol);

				this.loop_price_data = setInterval(() => {
					this.setPriceData(this.state.selected_symbol);
					this.setTokenBalance(this.state.selected_symbol);
				}, 15e3);
			}
		}
	}

	checkCoingeckoListing = (id) => {
		const { tokens } = this.state;
		const token = R.pathOr(null, [id], tokens);

		if (token) {
			const address = token.address;
			const token_data_url = "https://api.coingecko.com/api/v3/coins/ethereum/contract/" + address;

			axios({
				method: "GET",
				headers: { "content-type": "application/json" },
				url: token_data_url,
			})
				.then((token_data_result) => {
					const token_data_error = R.pathOr(null, ["data", "error"], token_data_result);

					if (!token_data_error) {
						const _state = {};

						_state.tokens = tokens;
						_state.tokens[id] = {
							...token,
							coingecko: true,
						};

						this.setState(
							{
								...this.state,
								..._state,
							},
							() => {
								this.updatePersistedTokens();
							}
						);
					}
				})
				.catch((error) => {
					if (error.response.status === 404) {
						const _state = {};

						_state.tokens = tokens;
						_state.tokens[id] = {
							...token,
							coingecko: false,
						};

						this.setState(
							{
								...this.state,
								..._state,
							},
							() => {
								this.updatePersistedTokens();
							}
						);
					}
				});
		}
	};

	checkEntryOrder = (entry) => {
		const { base_unit, crypto_price, price_data, tokens } = this.state;
		const { symbol } = entry;
		const token = R.pathOr(null, [symbol], tokens);

		const exchangeV2Contract = web3.eth.contract(abiUniswapV2Exchange);
		const exchangeV2Instance = exchangeV2Contract.at(token.exchangeV2);

		exchangeV2Instance.decimals((error, result) => {
			const decimals = parseInt(result ? result.toString() : 0, 10);
			const decimals_uint = Math.pow(10, decimals);
			const spend_amount = entry.spend_amount * decimals_uint;
			const token_decimals = R.pathOr(1, ["decimals"], token);
			const token_decimals_uint = Math.pow(10, token_decimals);

			exchangeV2Instance.getReserves((error, result) => {
				const outputReserve = +R.pathOr(0, [0], result).toString(10);
				const inputReserve = +R.pathOr(0, [1], result).toString(10);
				const token0 = outputReserve / decimals_uint;
				const token1 = inputReserve / token_decimals_uint;
				const inputAmountWithFee = spend_amount * 0.997;
				const numerator = inputAmountWithFee * outputReserve;
				const denominator = inputReserve + inputAmountWithFee;
				const proposed_order = numerator / denominator / token_decimals_uint;
				const current = R.pathOr(0, ["current"], price_data);
				const price = (crypto_price / proposed_order) * entry.spend_amount;
				const rate = token0 / token1;
				const amount = entry.spend_amount / rate;

				let	fulfilled_order;

				if (price === 0) {
					fulfilled_order = amount;
				} else if (deviation(price, current) > 5) {
					fulfilled_order = amount;
				} else {
					fulfilled_order = proposed_order;
				}

				if (fulfilled_order >= entry.total_order) {
					this.sendEntryOrder(entry, "v2");
				}
			});
		});
	};

	checkExitOrder = (exit) => {
		const { base_unit, crypto_price, price_data, tokens } = this.state;
		const { symbol } = exit;
		const token = R.pathOr(null, [symbol], tokens);

		const exchangeV2Contract = web3.eth.contract(abiUniswapV2Exchange);
		const exchangeV2Instance = exchangeV2Contract.at(token.exchangeV2);

		exchangeV2Instance.decimals((error, result) => {
			const decimals = parseInt(result ? result.toString() : 0, 10);
			const decimals_uint = Math.pow(10, decimals);
			const spend_amount = exit.spend_amount * decimals_uint;
			const token_decimals = R.pathOr(1, ["decimals"], token);
			const token_decimals_uint = Math.pow(10, token_decimals);

			exchangeV2Instance.getReserves((error, result) => {
				const outputReserve = +R.pathOr(0, [0], result).toString(10);
				const inputReserve = +R.pathOr(0, [1], result).toString(10);
				const token0 = outputReserve / decimals_uint;
				const token1 = inputReserve / token_decimals_uint;
				const inputAmountWithFee = spend_amount * 0.997;
				const numerator = inputAmountWithFee * outputReserve;
				const denominator = inputReserve + inputAmountWithFee;
				const proposed_order = numerator / denominator / token_decimals_uint;
				const current = R.pathOr(0, ["current"], price_data);
				const price = (crypto_price / proposed_order) * exit.spend_amount;
				const rate = token0 / token1;
				const amount = exit.spend_amount / rate;

				let	fulfilled_order;

				if (price === 0) {
					fulfilled_order = amount;
				} else if (deviation(price, current) > 5) {
					fulfilled_order = amount;
				} else {
					fulfilled_order = proposed_order;
				}

				if (exit.spend_amount / fulfilled_order > exit.position) {
					const amt_in = Math.floor(exit.total_order * token_decimals_uint);
					const amt_out = Math.floor(fulfilled_order * 1e18);

					this.sendExitOrder(exit, { amt_in: amt_in, amt_out: amt_out }, "v2");
				}
			});
		});
	};

	formatPrice = (number, currency) => {
		switch (currency) {
			case "usd":
				return "$" + number.toFixed(6);

			default:
				return "$" + number.toFixed(6);
		}
	};

	getAddressFromPrivateKey = (private_key) => {
		let public_key = publicKeyCreate(private_key, false);

		public_key = publicKeyConvert(public_key, false).slice(1);
		public_key = new Buffer(public_key, "hex");

		return toChecksumAddress(
			keccak256(public_key)
				.slice(-20)
				.toString("hex")
		);
	};

	getCryptoPrice = (id) => {
		return new Promise((resolve, reject) => {
			let token;

			switch (id) {
				case "ethereum":
					token = {
						exchangeV1: "0xA2881A90Bf33F03E7a3f803765Cd2ED5c8928dFb",
						exchangeV2: "0xB4e16d0168e52d35CaCD2c6185b44281Ec28C9Dc",
						decimals: 6,
					};

					// @Note: For Ropsten testnet Uniswap Exchange USDC/WETH pair
					// token = {
					// 	exchangeV1: null,
					// 	exchangeV2: "0xbc30AaA8e99d0f0e435FC938034850c2fC77f753",
					// 	decimals: 6,
					// };

					break;
				default:
					break;
			}

			const exchangeV2Contract = web3.eth.contract(abiUniswapV2Exchange);
			const exchangeV2Instance = exchangeV2Contract.at(token.exchangeV2);

			exchangeV2Instance.decimals((error, result) => {
				const decimals = parseInt(result ? result.toString() : 0, 10);
				const spend_amount = Math.pow(10, decimals);
				const token_decimals = R.pathOr(1, ["decimals"], token);
				const token_decimals_uint = Math.pow(10, token_decimals);

				exchangeV2Instance.getReserves((error, result) => {
					const outputReserve = +R.pathOr(0, [0], result).toString(10);
					const inputReserve = +R.pathOr(0, [1], result).toString(10);
					const inputAmountWithFee = spend_amount; // * 0.997;
					const numerator = inputAmountWithFee * outputReserve;
					const denominator = inputReserve + inputAmountWithFee;
					const proposed_order = numerator / denominator / token_decimals_uint;

					const _state = {};

					_state.crypto_price = proposed_order;

					this.setState(
						{
							...this.state,
							..._state,
						},
						() => {
							resolve(true);
						}
					);
				});
			});

			// @Note: For use when stablecoin isn't valid.
			// const price_url =
			// 	"https://api.coingecko.com/api/v3/simple/price?vs_currencies=USD&include_market_cap=true&include_24hr_vol=true&include_24hr_change=true&include_last_updated_at=true&ids=" + id;
			//
			// axios({
			// 	method: "GET",
			// 	headers: { "content-type": "application/json" },
			// 	url: price_url,
			// })
			// 	.then((price_result) => {
			// 		const value = R.pathOr(0, ["data", id, "usd"], price_result);
			// 		const _state = {};
			//
			// 		_state.crypto_price = value;
			//
			// 		this.setState({
			// 			...this.state,
			// 			..._state,
			// 		}, () => {
			// 			resolve(true);
			// 		});
			// 	})
			// 	.catch((error) => {
			// 		reject(error);
			// 	});
		});
	};

	getEthereumPrice = (id) => {
		return new Promise((resolve, reject) => {
			const price_url =
				"https://api.coingecko.com/api/v3/simple/price?ids=" + id + "&vs_currencies=ETH&include_market_cap=true&include_24hr_vol=true&include_24hr_change=true&include_last_updated_at=true";

			axios({
				method: "GET",
				headers: { "content-type": "application/json" },
				url: price_url,
			})
				.then((price_result) => {
					const price_eth = R.path(["data", id, "eth"], price_result);

					if (typeof price_eth !== "undefined" && Number.isNaN(price_eth) === false) {
						resolve(price_eth);
					} else {
						const { crypto_price } = this.state;

						this.getPriceData(id)
							.then((price) => {
								resolve(price / crypto_price);
							})
							.catch((error) => {
								reject(error);
							});
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	getGasPrice = () => {
		return new Promise((resolve, reject) => {
			const gas_url = "https://ethgasstation.info/api/ethgasAPI.json";

			axios({
				method: "GET",
				headers: { "content-type": "application/json" },
				url: gas_url,
			})
				.then((gas_result) => {
					const raw = {
						gasPrice: 35,
						gasLimit: 21000,
					};

					const gas_limits = {
						fast: {
							...raw,
							gasPrice: web3.toWei(Math.floor(+R.pathOr(0, ["data", "fast"], gas_result) / 10), "gwei"),
							gasLimit: 125e3,
						},
						fastest: {
							...raw,
							gasPrice: web3.toWei(Math.floor(+R.pathOr(0, ["data", "fastest"], gas_result) / 10), "gwei"),
							gasLimit: 125e3,
						},
						ultra: {
							...raw,
							gasPrice: web3.toWei(Math.floor(+R.pathOr(0, ["data", "fastest"], gas_result) / 6), "gwei"),
							gasLimit: 200e3,
						},
					};

					const _state = {};

					_state.gas_limits = gas_limits;

					this.setState(
						{
							...this.state,
							..._state,
						},
						() => {
							resolve(true);
						}
					);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	getPriceData = (id) => {
		return new Promise((resolve, reject) => {
			const { base_unit, crypto_price, tokens } = this.state;
			const token = R.pathOr(null, [id], tokens);

			if (token) {
				const exchangeV2Contract = web3.eth.contract(abiUniswapV2Exchange);
				const exchangeV2Instance = exchangeV2Contract.at(token.exchangeV2);

				exchangeV2Instance.decimals((error, result) => {
					const decimals = parseInt(result ? result.toString() : 0, 10);
					const decimals_uint = Math.pow(10, decimals);
					const spend_amount = base_unit * decimals_uint;
					const token_decimals = R.pathOr(1, ["decimals"], token);
					const token_decimals_uint = Math.pow(10, token_decimals);

					exchangeV2Instance.getReserves((error, result) => {
						const outputReserve = +R.pathOr(0, [0], result).toString(10);
						const inputReserve = +R.pathOr(0, [1], result).toString(10);
						const token0 = outputReserve / decimals_uint;
						const token1 = inputReserve / token_decimals_uint;
						const inputAmountWithFee = spend_amount * 0.997;
						const numerator = inputAmountWithFee * outputReserve;
						const denominator = inputReserve + inputAmountWithFee;
						const proposed_order = numerator / denominator / token_decimals_uint;

						let current = (crypto_price / proposed_order) * base_unit;

						const price = crypto_price > 0 ? (crypto_price / proposed_order) * base_unit : current;
						const rate = crypto_price > 0 ? (token0 / token1) * crypto_price : current;

						if (price === 0) {
							current = rate;
						} else if (deviation(price, current) > 5) {
							current = rate;
						} else {
							current = price;
						}

						resolve(current);
					});
				});
			} else {
				this.updateSelectedToken(id)
					.then((symbol) => {
						const _state = {};

						_state.selected_symbol = symbol;

						this.setState(
							{
								...this.state,
								..._state,
							},
							() => {
								this.updatePersistedSelectedSymbol();
							}
						);
					})
					.catch((error) => {
						reject(error);
					});
			}
		});
	};

	getTokenOptions = () => {
		const { tokens } = this.state;

		return new Promise((resolve, reject) => {
			const uniswap_token_url = "https://unpkg.com/@uniswap/default-token-list@1.3.0/build/uniswap-default.tokenlist.json";

			axios({
				method: "GET",
				headers: { "content-type": "application/json" },
				url: uniswap_token_url,
			})
				.then((uniswap_token_result) => {
					const uniswap_tokens = R.pathOr([], ["data", "tokens"], uniswap_token_result);
					const token_options = [];
					const _state = {};

					_state.uniswap_tokens = uniswap_tokens;

					this.setState({
						...this.state,
						..._state,
					});

					Object.keys(tokens).forEach((key) => {
						uniswap_tokens.forEach((token) => {
							if (token.address.toLowerCase() === tokens[key].address.toLowerCase()) {
								token_options.push({
									address: token.address.toLowerCase(),
									approved: tokens[key].approved,
									key: token.address.toLowerCase(),
									text: token.name + " (" + token.symbol + ")",
									value: tokens[key].symbol,
									image: { avatar: true, src: token.logoURI },
								});
							}
						});
					});

					Object.keys(tokens).forEach((key) => {
						if (R.find(R.propEq("address", tokens[key].address.toLowerCase()))(token_options) === undefined) {
							token_options.push({
								address: tokens[key].address.toLowerCase(),
								approved: tokens[key].approved,
								key: tokens[key].address.toLowerCase(),
								text: tokens[key].label + " (" + tokens[key].ticker + ")",
								value: tokens[key].symbol,
								image: { avatar: true, src: tokens[key].logo ? tokens[key].logo : "/curved.png" },
							});
						}
					});

					uniswap_tokens.forEach((uniswap_token) => {
						if (R.find(R.propEq("address", uniswap_token.address.toLowerCase()))(token_options) === undefined) {
							token_options.push({
								address: uniswap_token.address.toLowerCase(),
								approved: false,
								key: uniswap_token.address.toLowerCase(),
								text: uniswap_token.name + " (" + uniswap_token.symbol + ")",
								value: uniswap_token.address.toLowerCase(),
								image: { avatar: true, src: uniswap_token.logoURI },
							});
						}
					});

					resolve(token_options);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	handleModal = (event, modal) => {
		const _state = {};

		_state.modal_enter = this.state.modal_enter ? !this.state.modal_enter : modal === 1;
		_state.modal_exit = this.state.modal_exit ? !this.state.modal_exit : modal === 2;
		_state.modal_enter_exit = this.state.modal_enter_exit ? !this.state.modal_enter_exit : modal === 3;

		this.setState(
			{
				...this.state,
				..._state,
			},
			() => {
				const { active_order, orders } = this.state;

				if (active_order) {
					const isOrder = R.pathEq(["id"], active_order);
					const isEntry = R.pathEq(["type"], "entry");
					const matchedOrders = R.filter(R.both(isOrder, isEntry), orders);

					const symbol = R.pathOr(null, [0, "symbol"], matchedOrders);

					this.getEthereumPrice(symbol)
						.then((result) => {
							const _state = {};

							_state.active_order_price = result;

							this.setState({
								...this.state,
								..._state,
							});
						})
						.catch((error) => {
							console.error(error);
						});
				}

				if (modal === "enter_exit") {
					this.handleModal(null, 3);
				}
			}
		);
	};

	pollWatchlist = () => {
		const { orders, watchlist } = this.state;

		watchlist.forEach((symbol, index) => {
			const isSymbol = R.pathEq(["symbol"], symbol);
			const isActive = R.pathEq(["status"], "active");
			const isOpen = R.pathEq(["order"], "open");
			const isEntry = R.pathEq(["type"], "entry");
			const isExit = R.pathEq(["type"], "exit");

			const entry_orders = R.filter(R.both(R.both(R.both(isActive, isSymbol), isEntry), isOpen), orders);
			const exit_orders = R.filter(R.both(R.both(R.both(isActive, isSymbol), isExit), isOpen), orders);

			entry_orders.forEach((entry_order, index) => {
				this.checkEntryOrder(entry_order);
			});

			exit_orders.forEach((exit_order, index) => {
				this.checkExitOrder(exit_order);
			});

			/*
			const isOrdered = R.pathEq(["order"], "ordered");
			const ordered_orders = R.filter(R.both(R.both(R.both(isActive, isSymbol), isEntry), isOrdered), orders);

			ordered_orders.forEach((ordered_order, index) => {
				const { id } = ordered_order;
				const isMatched = R.pathEq(["id"], id);

				const matched_orders = R.filter(isMatched, exit_orders);
				const exit_order = R.pathOr({}, [0], matched_orders);

				this.checkExitOrder(exit_order);
			});
			*/
		});
	};

	refreshSignedValues = () => {
		const { private_key } = this.state;

		if (private_key && private_key !== "") {
			const pk = new Buffer(private_key, "hex");
			const account = this.getAddressFromPrivateKey(pk);

			web3.eth.getBalance(account, web3.eth.defaultBlock, (error, balance) => {
				const _state = {};

				if (balance) {
					_state.account = account;
					_state.balance = (balance.toString(10) / 1e18).toFixed(6);
					_state.order_entry_amount = _state.balance * 0.01;

					this.setState({
						...this.state,
						..._state,
					});
				}
			});
		}
	};

	renderCustomTransactionFee = (order, type) => {
		return (
			<div className="d-flex justify-content-between">
				<FormInput
					placeholder="Gas Price (gwei)"
					type="number"
					className="custom-gas-input mr-2"
					onKeyUp={(event) => {
						const _state = {};

						_state.custom_gas_price = event.target.value;

						this.setState({
							...this.state,
							..._state,
						});
					}}
					onChange={(event) => {
						const _state = {};

						_state.custom_gas_price = event.target.value;

						this.setState({
							...this.state,
							..._state,
						});
					}}
				/>
				<FormInput
					placeholder="Gas Limit"
					type="number"
					className="custom-gas-input mr-2"
					onKeyUp={(event) => {
						const _state = {};

						_state.custom_gas_limit = event.target.value;

						this.setState({
							...this.state,
							..._state,
						});
					}}
					onChange={(event) => {
						const _state = {};

						_state.custom_gas_limit = event.target.value;

						this.setState({
							...this.state,
							..._state,
						});
					}}
				/>
				<div className="d-flex justify-content-around">
					<Button
						onClick={() => {
							const _state = {};

							_state.custom_gas_price = 0;
							_state.custom_gas_limit = 0;
							_state.gas = "fastest";

							if (type === "entry") {
								_state.toggle_custom_entry_fee_input = false;
							}

							if (type === "exit") {
								_state.toggle_custom_exit_fee_input = false;
							}

							this.setState({
								...this.state,
								..._state,
							});
						}}
						className="custom-gas-button mr-1 d-flex justify-content-center align-items-center text-center">
						<img
							alt=""
							src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTYiIGhlaWdodD0iOTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CgogPGc+CiAgPHRpdGxlPmJhY2tncm91bmQ8L3RpdGxlPgogIDxyZWN0IGZpbGw9Im5vbmUiIGlkPSJjYW52YXNfYmFja2dyb3VuZCIgaGVpZ2h0PSI0MDIiIHdpZHRoPSI1ODIiIHk9Ii0xIiB4PSItMSIvPgogPC9nPgogPGc+CiAgPHRpdGxlPkxheWVyIDE8L3RpdGxlPgogIDxwYXRoIGlkPSJzdmdfMiIgZmlsbD0iI2ZmZmZmZiIgZD0ibTM0LjA1MSw3NC42MThjLTMuMTExLDMuMTExIC04LjIwMywzLjExMSAtMTEuMzE0LDBsLTEuNDEzLC0xLjQxM2MtMy4xMTEsLTMuMTExIC0zLjExMSwtOC4yMDMgMCwtMTEuMzE0bDQwLjYyNiwtNDAuNjI2YzMuMTExLC0zLjExMSA4LjIwMywtMy4xMTEgMTEuMzE0LDBsMS40MTMsMS40MTRjMy4xMTEsMy4xMTEgMy4xMTEsOC4yMDIgMCwxMS4zMTNsLTQwLjYyNiw0MC42MjZ6Ii8+CiAgPHBhdGggaWQ9InN2Z180IiBmaWxsPSIjZmZmZmZmIiBkPSJtNzMuMjYzLDc0LjYxOGMtMy4xMSwzLjExMSAtOC4yMDIsMy4xMTEgLTExLjMxMywwbC00MC42MjcsLTQwLjYyN2MtMy4xMTEsLTMuMTExIC0zLjExMSwtOC4yMDIgMCwtMTEuMzEzbDEuNDEzLC0xLjQxNGMzLjExMSwtMy4xMTEgOC4yMDMsLTMuMTExIDExLjMxNCwwbDQwLjYyNyw0MC42MjZjMy4xMTEsMy4xMTEgMy4xMTEsOC4yMDMgMC4wMDEsMTEuMzE0bC0xLjQxNSwxLjQxNHoiLz4KIDwvZz4KPC9zdmc+"
						/>
					</Button>
					<Button
						onClick={() => {
							const { custom_gas_price, custom_gas_limit } = this.state;
							const _state = {};

							_state.custom_gas_price = 0;
							_state.custom_gas_limit = 0;

							if (type === "entry") {
								_state.toggle_custom_entry_fee_input = false;
							}

							if (type === "exit") {
								_state.toggle_custom_exit_fee_input = false;
							}

							_state.gas = {
								gasPrice: web3.toWei(Math.floor(custom_gas_price, "gwei")),
								gasLimit: custom_gas_limit,
							};

							this.setState({
								...this.state,
								..._state,
							});
						}}
						className="custom-gas-button ml-1 d-flex justify-content-center align-items-center text-center">
						<img
							alt=""
							src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8dGl0bGUvPgoKIDxnPgogIDx0aXRsZT5iYWNrZ3JvdW5kPC90aXRsZT4KICA8cmVjdCBmaWxsPSJub25lIiBpZD0iY2FudmFzX2JhY2tncm91bmQiIGhlaWdodD0iNDAyIiB3aWR0aD0iNTgyIiB5PSItMSIgeD0iLTEiLz4KIDwvZz4KIDxnPgogIDx0aXRsZT5MYXllciAxPC90aXRsZT4KICA8cGF0aCBmaWxsPSIjZmZmZmZmIiBpZD0ic3ZnXzEiIGQ9Im0yMS4yNSw1LjkybC00LjM1LC0zLjQ4YTIsMiAwIDAgMCAtMS4yNSwtMC40NGwtMTEuNjUsMGEyLDIgMCAwIDAgLTIsMmwwLDE2YTIsMiAwIDAgMCAyLDJsMTYsMGEyLDIgMCAwIDAgMiwtMmwwLC0xMi41MmEyLDIgMCAwIDAgLTAuNzUsLTEuNTZ6bS01LjI1LC0xLjY0bDAsMi43MmwtOCwwbDAsLTNsNy42NSwwbDAuMzUsMC4yOHptMCwxNS43MmwtOCwwbDAsLTdsOCwwbDAsN3ptMiwwbDAsLThhMSwxIDAgMCAwIC0xLC0xbC0xMCwwYTEsMSAwIDAgMCAtMSwxbDAsOGwtMiwwbDAsLTE2bDIsMGwwLDRhMSwxIDAgMCAwIDEsMWwxMCwwYTEsMSAwIDAgMCAxLC0xbDAsLTIuMTJsMiwxLjZsMCwxMi41MmwtMiwweiIvPgogPC9nPgo8L3N2Zz4="
						/>
					</Button>
				</div>
			</div>
		);
	};

	renderOpenOrders = () => {
		const { active_order, crypto_price, disable_entrance, disable_exit, orders } = this.state;

		const activeOrders = R.pathEq(["status"], "active");
		const isEntryOrder = R.pathEq(["type"], "entry");
		const isExitOrder = R.pathEq(["type"], "exit");
		const matchedEntryExits = R.filter(R.both(activeOrders, isEntryOrder), orders);
		const matchedExitsOnly = R.filter(R.both(activeOrders, isExitOrder), orders);

		return (
			<Row>
				{matchedEntryExits.map((order, index) => {
					const orderId = order.id;
					const isOrder = R.pathEq(["id"], orderId);
					const isExitOrder = R.pathEq(["type"], "exit");
					const exitOrder = R.filter(R.both(isOrder, isExitOrder), orders);

					const exit_position = R.pathOr(0, [0, "position"], exitOrder);
					const total_exit_order = R.pathOr(0, [0, "total_order"], exitOrder);

					return (
						<Col key={"ord_" + index} lg="6" md="6" sm="12" className="order mb-3 mb-md-3">
							<Card>
								<CardHeader className="text-center">
									<span>TRADE&nbsp;{order.total_order.toFixed(2)}&nbsp;</span>
									<span className="badge badge-success position-relative">{order.ticker}</span>
									<br />
									<span>&nbsp;@&nbsp;{order.position.toFixed(6)}&nbsp;ETH</span>
								</CardHeader>
								<CardBody>
									<Row>
										<Col lg="6" md="6" sm="6" className="order-position">
											<div className={["status-icon", "position-absolute", order.order === "ordered" ? "ordered" : ""].join(" ")} />
											<h6 className="text-center">
												BUY&nbsp;
												<span className="badge badge-success position-relative">{order.ticker}</span>
											</h6>
											<p className="text-center">
												{order.total_order.toFixed(5)}
												<br />
												@&nbsp;{order.position.toFixed(6)}&nbsp;({(order.position * crypto_price).toFixed(2)})
											</p>
										</Col>
										{exitOrder.length > 0 && (
											<Col lg="6" md="6" sm="6" className="order-position">
												<div className={["status-icon", "position-absolute", exitOrder[0].order === "exited" ? "exited" : ""].join(" ")} />
												<h6 className="text-center">
													SELL&nbsp;
													<span className="badge badge-success position-relative">{order.ticker}</span>
												</h6>
												<p className="text-center">
													{total_exit_order.toFixed(5)}
													<br />
													@&nbsp;{exit_position.toFixed(6)}&nbsp;({(exit_position * crypto_price).toFixed(2)})
												</p>
											</Col>
										)}
										{exitOrder.length < 1 && (
											<Col lg="6" md="6" sm="6" className="order-position">
												<h6 className="text-center">
													SELL&nbsp;
													<span className="badge badge-success position-relative">{order.ticker}</span>
												</h6>
												<p className="text-center">
													<span className="pending-badge badge badge-warning position-relative">SELL</span>
												</p>
											</Col>
										)}
									</Row>
								</CardBody>
								<CardFooter className="d-flex justify-content-end">
									<div className="d-flex justify-content-end">
										<div
											className="order-icon mr-3"
											onClick={(event) => {
												const _state = {};

												_state.orders = orders.map((order, index) => {
													if (order.id === orderId && order.order !== "paused") {
														order.order = "paused";
													}

													if (order.id === orderId && order.order === "paused") {
														order.order = "open";
													}

													return order;
												});

												this.setState({
													...this.state,
													..._state,
												});
											}}>
											<img
												alt=""
												src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZGF0YS1uYW1lPSJMYXllciAxIiBpZD0iTGF5ZXJfMSIgdmlld0JveD0iMCAwIDE2IDE2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjx0aXRsZS8+PHBhdGggZD0iTTgsMS40QTYuNiw2LjYsMCwxLDEsMS40LDgsNi42MSw2LjYxLDAsMCwxLDgsMS40TTgsMGE4LDgsMCwxLDAsOCw4QTgsOCwwLDAsMCw4LDBaIi8+PHJlY3QgaGVpZ2h0PSI0IiByeD0iMC41IiByeT0iMC41IiB3aWR0aD0iMSIgeD0iNi41IiB5PSI2Ii8+PHJlY3QgaGVpZ2h0PSI0IiByeD0iMC41IiByeT0iMC41IiB3aWR0aD0iMSIgeD0iOC41IiB5PSI2Ii8+PC9zdmc+"
											/>
											{/*<img alt="" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZGF0YS1uYW1lPSJMYXllciAxIiBpZD0iTGF5ZXJfMSIgdmlld0JveD0iMCAwIDE2IDE2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjx0aXRsZS8+PHBhdGggZD0iTTgsMS40QTYuNiw2LjYsMCwxLDEsMS40LDgsNi42MSw2LjYxLDAsMCwxLDgsMS40TTgsMGE4LDgsMCwxLDAsOCw4QTgsOCwwLDAsMCw4LDBaIi8+PHBhdGggZD0iTTEwLjU1LDguMjIsNi42OCwxMC40NWEuMjUuMjUsMCwwLDEtLjM3LS4yMlY1Ljc2YS4yNS4yNSwwLDAsMSwuMzgtLjIybDMuODcsMi4yNEEuMjUuMjUsMCwwLDEsMTAuNTUsOC4yMloiLz48L3N2Zz4=" />*/}
										</div>
										<div
											className="order-icon delete"
											onClick={(event) => {
												const _state = {};

												_state.orders = orders.filter((order, index) => {
													if (active_order === orderId) {
														_state.active_order = null;

														if (disable_entrance === true) {
															_state.disable_entrance = false;
														}

														if (disable_exit === true) {
															_state.disable_exit = false;
														}
													}

													if (order.id !== orderId) {
														return order;
													}

													return null;
												});

												this.setState(
													{
														...this.state,
														..._state,
													},
													() => {
														this.updatePersistedOrders();
													}
												);
											}}>
											<img
												alt=""
												src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZGF0YS1uYW1lPSJMYXllciAxIiBpZD0iTGF5ZXJfMSIgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDpub25lO308L3N0eWxlPjwvZGVmcz48dGl0bGUvPjxyZWN0IGNsYXNzPSJjbHMtMSIgaGVpZ2h0PSIyIiB3aWR0aD0iNiIgeD0iOSIgeT0iMyIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTE4LjA2LDIxTDE4Ljk0LDdINS4wNkw1Ljk0LDIxSDE4LjA2Wk0xMywxMGExLDEsMCwwLDEsMiwwdjhhMSwxLDAsMCwxLTIsMFYxMFpNOSwxMGExLDEsMCwwLDEsMiwwdjhhMSwxLDAsMCwxLTIsMFYxMFoiLz48cGF0aCBkPSJNMiw3SDMuMDZMNCwyMi4wNkExLDEsMCwwLDAsNSwyM0gxOWExLDEsMCwwLDAsMS0uOTRMMjAuOTQsN0gyMmExLDEsMCwwLDAsMC0ySDE3VjNhMiwyLDAsMCwwLTItMkg5QTIsMiwwLDAsMCw3LDNWNUgyQTEsMSwwLDAsMCwyLDdaTTksM2g2VjVIOVYzWk04LDdIMTguOTRMMTguMDYsMjFINS45NEw1LjA2LDdIOFoiLz48cGF0aCBkPSJNMTAsMTlhMSwxLDAsMCwwLDEtMVYxMGExLDEsMCwwLDAtMiwwdjhBMSwxLDAsMCwwLDEwLDE5WiIvPjxwYXRoIGQ9Ik0xNCwxOWExLDEsMCwwLDAsMS0xVjEwYTEsMSwwLDAsMC0yLDB2OEExLDEsMCwwLDAsMTQsMTlaIi8+PC9zdmc+"
											/>
										</div>
									</div>
								</CardFooter>
							</Card>
						</Col>
					);
				})}
				{matchedExitsOnly.map((order, index) => {
					const orderId = order.id;
					const isOrder = R.pathEq(["id"], orderId);
					const entryOrder = R.find(R.both(isEntryOrder, isOrder))(orders);

					if (!entryOrder) {
						const exit_position = R.pathOr(0, ["position"], order);
						const total_exit_order = R.pathOr(0, ["total_order"], order);

						return (
							<Col key={"ord_" + index} lg="6" md="6" sm="12" className="order mb-3 mb-md-3">
								<Card>
									<CardHeader className="text-center">
										<span>TRADE&nbsp;{order.total_order.toFixed(2)}&nbsp;</span>
										<span className="badge badge-success position-relative">{order.ticker}</span>
										<br />
										<span>&nbsp;@&nbsp;{order.position.toFixed(6)}&nbsp;ETH</span>
									</CardHeader>
									<CardBody>
										<Row>
											<Col lg="6" md="6" sm="6" className="order-position">
												<div className="status-icon position-absolute forbid" />
												<h6 className="text-center">
													BUY&nbsp;
													<span className="badge badge-success position-relative">{order.ticker}</span>
												</h6>
												<p className="text-center">
													<span className="pending-badge badge badge-warning position-relative">SELL ONLY</span>
												</p>
											</Col>
											<Col lg="6" md="6" sm="6" className="order-position">
												<div className={["status-icon", "position-absolute", order.order === "exited" ? "exited" : ""].join(" ")} />
												<h6 className="text-center">
													SELL&nbsp;
													<span className="badge badge-success position-relative">{order.ticker}</span>
												</h6>
												<p className="text-center">
													{total_exit_order.toFixed(5)}
													<br />
													@&nbsp;{exit_position.toFixed(6)}&nbsp;({(exit_position * crypto_price).toFixed(2)})
												</p>
											</Col>
										</Row>
									</CardBody>
									<CardFooter className="d-flex justify-content-end">
										<div className="d-flex justify-content-end">
											<div
												className="order-icon mr-3"
												onClick={(event) => {
													const _state = {};

													_state.orders = orders.map((order, index) => {
														if (order.id === orderId && order.order !== "paused") {
															order.order = "paused";
														}

														if (order.id === orderId && order.order === "paused") {
															order.order = "open";
														}

														return order;
													});

													this.setState({
														...this.state,
														..._state,
													});
												}}>
												<img
													alt=""
													src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZGF0YS1uYW1lPSJMYXllciAxIiBpZD0iTGF5ZXJfMSIgdmlld0JveD0iMCAwIDE2IDE2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjx0aXRsZS8+PHBhdGggZD0iTTgsMS40QTYuNiw2LjYsMCwxLDEsMS40LDgsNi42MSw2LjYxLDAsMCwxLDgsMS40TTgsMGE4LDgsMCwxLDAsOCw4QTgsOCwwLDAsMCw4LDBaIi8+PHJlY3QgaGVpZ2h0PSI0IiByeD0iMC41IiByeT0iMC41IiB3aWR0aD0iMSIgeD0iNi41IiB5PSI2Ii8+PHJlY3QgaGVpZ2h0PSI0IiByeD0iMC41IiByeT0iMC41IiB3aWR0aD0iMSIgeD0iOC41IiB5PSI2Ii8+PC9zdmc+"
												/>
												{/* <img alt="" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZGF0YS1uYW1lPSJMYXllciAxIiBpZD0iTGF5ZXJfMSIgdmlld0JveD0iMCAwIDE2IDE2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjx0aXRsZS8+PHBhdGggZD0iTTgsMS40QTYuNiw2LjYsMCwxLDEsMS40LDgsNi42MSw2LjYxLDAsMCwxLDgsMS40TTgsMGE4LDgsMCwxLDAsOCw4QTgsOCwwLDAsMCw4LDBaIi8+PHBhdGggZD0iTTEwLjU1LDguMjIsNi42OCwxMC40NWEuMjUuMjUsMCwwLDEtLjM3LS4yMlY1Ljc2YS4yNS4yNSwwLDAsMSwuMzgtLjIybDMuODcsMi4yNEEuMjUuMjUsMCwwLDEsMTAuNTUsOC4yMloiLz48L3N2Zz4=" /> */}
											</div>
											<div
												className="order-icon delete"
												onClick={(event) => {
													const _state = {};

													_state.orders = orders.filter((order, index) => {
														if (active_order === orderId) {
															_state.active_order = null;

															if (disable_entrance === true) {
																_state.disable_entrance = false;
															}

															if (disable_exit === true) {
																_state.disable_exit = false;
															}
														}

														if (order.id !== orderId) {
															return order;
														}

														return null;
													});

													this.setState(
														{
															...this.state,
															..._state,
														},
														() => {
															this.updatePersistedOrders();
														}
													);
												}}>
												<img
													alt=""
													src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZGF0YS1uYW1lPSJMYXllciAxIiBpZD0iTGF5ZXJfMSIgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDpub25lO308L3N0eWxlPjwvZGVmcz48dGl0bGUvPjxyZWN0IGNsYXNzPSJjbHMtMSIgaGVpZ2h0PSIyIiB3aWR0aD0iNiIgeD0iOSIgeT0iMyIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTE4LjA2LDIxTDE4Ljk0LDdINS4wNkw1Ljk0LDIxSDE4LjA2Wk0xMywxMGExLDEsMCwwLDEsMiwwdjhhMSwxLDAsMCwxLTIsMFYxMFpNOSwxMGExLDEsMCwwLDEsMiwwdjhhMSwxLDAsMCwxLTIsMFYxMFoiLz48cGF0aCBkPSJNMiw3SDMuMDZMNCwyMi4wNkExLDEsMCwwLDAsNSwyM0gxOWExLDEsMCwwLDAsMS0uOTRMMjAuOTQsN0gyMmExLDEsMCwwLDAsMC0ySDE3VjNhMiwyLDAsMCwwLTItMkg5QTIsMiwwLDAsMCw3LDNWNUgyQTEsMSwwLDAsMCwyLDdaTTksM2g2VjVIOVYzWk04LDdIMTguOTRMMTguMDYsMjFINS45NEw1LjA2LDdIOFoiLz48cGF0aCBkPSJNMTAsMTlhMSwxLDAsMCwwLDEtMVYxMGExLDEsMCwwLDAtMiwwdjhBMSwxLDAsMCwwLDEwLDE5WiIvPjxwYXRoIGQ9Ik0xNCwxOWExLDEsMCwwLDAsMS0xVjEwYTEsMSwwLDAsMC0yLDB2OEExLDEsMCwwLDAsMTQsMTlaIi8+PC9zdmc+"
												/>
											</div>
										</div>
									</CardFooter>
								</Card>
							</Col>
						);
					} else {
						return null;
					}
				})}
			</Row>
		);
	};

	renderOrderActions = () => {
		const { disable_entrance, disable_exit } = this.state;

		return (
			<React.Fragment>
				<Col lg="6" md="6" sm="12" className="mb-1 mb-md-0">
					<Button
						className="buy-button w-100"
						disabled={disable_entrance}
						onClick={(event) => {
							this.handleModal(event, 1);
						}}>
						BUY
					</Button>
				</Col>
				<Col lg="6" md="6" sm="12" className="mt-2 mt-md-0">
					<Button
						className="sell-button w-100"
						disabled={disable_exit}
						onClick={(event) => {
							this.handleModal(event, 2);
						}}>
						SELL
					</Button>
				</Col>
			</React.Fragment>
		);
	};

	renderOrderEntryForm = () => {
		const {
			active_order,
			balance,
			create_exit_order,
			currency,
			gas,
			order_entry_amount,
			position_entry_amount,
			price_data,
			selected_symbol,
			slider_order_entry_value,
			slider_position_entry_value,
			toggle_custom_entry_fee_input,
			toggle_position_entry_slider,
			toggle_order_entry_label,
			toggle_order_entry_slider,
			tokens,
		} = this.state;

		const label = R.pathOr("", [selected_symbol, "label"], tokens);

		const { high, low } = price_data;
		const _low = +low.toFixed(6);
		const _high = +high.toFixed(6);

		const filterPips = (value, type) => {
			if (type === 1) {
				return value === _low || value === _high ? 1 : 0;
			}

			return 0;
		};

		return (
			<React.Fragment>
				<FormGroup className="order-form-group pb-4 px-1">
					<div className="d-flex justify-content-between position-relative">
						<h6 className="w-50">
							<span className="order-label">WALLET: {balance}&nbsp;ETH</span>
						</h6>
						<div className="d-block-full w-50">
							{!toggle_order_entry_label && (
								<h6 className="text-right" onClick={this.toggleLabelEntry}>
									<span className="order-label">ORDER: {order_entry_amount.toFixed(6)}&nbsp;ETH</span>
								</h6>
							)}
							{toggle_order_entry_label && (
								<div className="d-block-full">
									<h6 className="d-flex flex-justify-between">
										<span className="order-label mr-2" onClick={this.toggleLabelEntry}>
											ORDER:
										</span>
										<FormInput className="w-75" size="sm" placeholder={order_entry_amount.toFixed(8)} type="number" />
									</h6>
								</div>
							)}
						</div>
						<div
							className="keypad-icon position-absolute"
							onClick={(event) => {
								const _state = {};

								_state.toggle_order_entry_slider = !this.state.toggle_order_entry_slider;

								this.setState({
									...this.state,
									..._state,
								});
							}}>
							{!toggle_order_entry_slider && (
								<img
									alt=""
									src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDIwIDIwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0wIDZjMC0xLjEuOS0yIDItMmgxNmEyIDIgMCAwIDEgMiAydjhhMiAyIDAgMCAxLTIgMkgyYTIgMiAwIDAgMS0yLTJWNnptMiAwdjJoMlY2SDJ6bTEgM3YyaDJWOUgzem0tMSAzdjJoMnYtMkgyem0zIDB2MmgxMHYtMkg1em0xMSAwdjJoMnYtMmgtMnpNNiA5djJoMlY5SDZ6bTMgMHYyaDJWOUg5em0zIDB2MmgyVjloLTJ6bTMgMHYyaDJWOWgtMnpNNSA2djJoMlY2SDV6bTMgMHYyaDJWNkg4em0zIDB2MmgyVjZoLTJ6bTMgMHYyaDRWNmgtNHoiLz48L3N2Zz4="
								/>
							)}
							{toggle_order_entry_slider && (
								<img
									alt=""
									className="slider-icon"
									src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDMwIDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlLz48ZyBkYXRhLW5hbWU9IkxheWVyIDIiIGlkPSJMYXllcl8yIj48ZyBpZD0iSW50ZXJmYWNlLVNvbGlkIj48ZyBpZD0iaW50ZXJmYWNlLXNvbGlkLXNsaWRlciI+PHBhdGggZD0iTTExLjAzMTI1LDNIMUExLDEsMCwwLDAsMSw1SDExLjAzMTI1YTEsMSwwLDAsMCwwLTJaIi8+PHBhdGggZD0iTTI5LDNIMjdhMSwxLDAsMCwwLDAsMmgyYTEsMSwwLDAsMCwwLTJaIi8+PGNpcmNsZSBjeD0iMTkiIGN5PSI0IiByPSI0Ii8+PC9nPjwvZz48L2c+PC9zdmc+"
								/>
							)}
						</div>
					</div>
					{!toggle_order_entry_slider && (
						<Slider
							className="order-slider mx-3"
							connect={[true, false]}
							onSlide={this.updateEntryAmount}
							pips={{
								density: 100,
								mode: "steps",
								stepped: false,
								format: wNumb({
									suffix: "%",
								}),
							}}
							range={{ min: 1, max: 100 }}
							start={[slider_order_entry_value]}
						/>
					)}
					{toggle_order_entry_slider && (
						<FormInput
							className="order-amount-input mb-2 mt-4"
							type="number"
							placeholder="Enter order amount in Ethereum. Example: 1.00"
							onKeyUp={(event) => {
								if (Number.isNaN(event.target.value) === false) {
									const { currency, crypto_price } = this.state;

									const _state = {};

									_state.order_entry_amount = +event.target.value;

									switch (currency) {
										case "usd":
											_state.position_entry_amount = _state.order_entry_amount * crypto_price;
											break;
										default:
											_state.position_entry_amount = 0;
											break;
									}

									this.setState({
										...this.state,
										..._state,
									});
								} else {
									this.updateEntryAmount([1]);
								}
							}}
							onChange={(event) => {
								if (Number.isNaN(event.target.value) === false) {
									const { currency, crypto_price } = this.state;

									const _state = {};

									_state.order_entry_amount = +event.target.value;

									switch (currency) {
										case "usd":
											_state.position_entry_amount = _state.order_entry_amount * crypto_price;
											break;
										default:
											_state.position_entry_amount = 0;
											break;
									}

									this.setState({
										...this.state,
										..._state,
									});
								} else {
									this.updateEntryAmount([1]);
								}
							}}
						/>
					)}
				</FormGroup>
				<FormGroup className="order-form-group pb-4 px-1">
					<div className="d-flex justify-content-between position-relative">
						<h6 htmlFor="entry-position-input">
							PURCHASING&nbsp;{position_entry_amount.toFixed(6)} {label}&nbsp;@&nbsp;${slider_position_entry_value}&nbsp;{currency.toUpperCase()}
						</h6>
						<div
							className="keypad-icon position-absolute"
							onClick={(event) => {
								const _state = {};

								_state.toggle_position_entry_slider = !this.state.toggle_position_entry_slider;

								this.setState({
									...this.state,
									..._state,
								});
							}}>
							{!toggle_position_entry_slider && (
								<img
									alt=""
									src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDIwIDIwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0wIDZjMC0xLjEuOS0yIDItMmgxNmEyIDIgMCAwIDEgMiAydjhhMiAyIDAgMCAxLTIgMkgyYTIgMiAwIDAgMS0yLTJWNnptMiAwdjJoMlY2SDJ6bTEgM3YyaDJWOUgzem0tMSAzdjJoMnYtMkgyem0zIDB2MmgxMHYtMkg1em0xMSAwdjJoMnYtMmgtMnpNNiA5djJoMlY5SDZ6bTMgMHYyaDJWOUg5em0zIDB2MmgyVjloLTJ6bTMgMHYyaDJWOWgtMnpNNSA2djJoMlY2SDV6bTMgMHYyaDJWNkg4em0zIDB2MmgyVjZoLTJ6bTMgMHYyaDRWNmgtNHoiLz48L3N2Zz4="
								/>
							)}
							{toggle_position_entry_slider && (
								<img
									alt=""
									className="slider-icon"
									src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDMwIDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlLz48ZyBkYXRhLW5hbWU9IkxheWVyIDIiIGlkPSJMYXllcl8yIj48ZyBpZD0iSW50ZXJmYWNlLVNvbGlkIj48ZyBpZD0iaW50ZXJmYWNlLXNvbGlkLXNsaWRlciI+PHBhdGggZD0iTTExLjAzMTI1LDNIMUExLDEsMCwwLDAsMSw1SDExLjAzMTI1YTEsMSwwLDAsMCwwLTJaIi8+PHBhdGggZD0iTTI5LDNIMjdhMSwxLDAsMCwwLDAsMmgyYTEsMSwwLDAsMCwwLTJaIi8+PGNpcmNsZSBjeD0iMTkiIGN5PSI0IiByPSI0Ii8+PC9nPjwvZz48L2c+PC9zdmc+"
								/>
							)}
						</div>
					</div>
					{!toggle_position_entry_slider && (
						<Slider
							className="entry-position-slider enter mx-3"
							connect={[true, false]}
							onSlide={this.updateEntryPrice}
							pips={{
								density: 100,
								mode: "steps",
								filter: filterPips,
								stepped: false,
								format: wNumb({ decimals: 6 }),
							}}
							range={{ min: [_low], max: [_high] }}
							start={[slider_position_entry_value]}
						/>
					)}
					{toggle_position_entry_slider && (
						<FormInput
							className="entry-position-input mb-2 mt-4"
							type="number"
							placeholder="Enter buy price. Example: 1.234567"
							onKeyUp={(event) => {
								if (Number.isNaN(event.target.value) === false) {
									this.updateEntryPrice([event.target.value]);
								} else {
									this.updateEntryPrice([0]);
								}
							}}
							onChange={(event) => {
								if (Number.isNaN(event.target.value) === false) {
									this.updateEntryPrice([event.target.value]);
								} else {
									this.updateEntryPrice([0]);
								}
							}}
						/>
					)}
				</FormGroup>
				<FormGroup className="order-form-group pb-4 px-1 position-relative">
					<div className="d-flex justify-content-between position-relative">
						<h6 htmlFor="entry-transaction-fee-select">CREATE EXIT ORDER?</h6>
					</div>
					<label className="autonomous-order-label exit">Select whether to auto-create exit order.</label>
					<FormCheckbox
						className="autonomous-order p-0 mb-0"
						toggle
						small
						checked={create_exit_order}
						onChange={() => {
							const _state = {};

							_state.create_exit_order = !this.state.create_exit_order;

							this.setState({
								...this.state,
								..._state,
							});
						}}>
						YES
					</FormCheckbox>
				</FormGroup>
				{toggle_custom_entry_fee_input && (
					<FormGroup className="order-form-group pb-0 mb-0 px-1">
						<div className="d-flex justify-content-between position-relative">
							<h6 htmlFor="entry-transaction-fee-select">TRANSACTION FEE</h6>
						</div>
						{this.renderCustomTransactionFee(active_order, "entry")}
					</FormGroup>
				)}
				{!toggle_custom_entry_fee_input && (
					<FormGroup className="order-form-group pb-0 mb-0 px-1">
						<div className="d-flex justify-content-between position-relative">
							<h6 htmlFor="entry-transaction-fee-select">TRANSACTION FEE</h6>
						</div>
						<select
							id="entry-transaction-fee-select"
							defaultValue={typeof gas === "string" ? gas : "custom"}
							onChange={(event) => {
								const _state = {};

								if (event.target.value === "custom") {
									_state.toggle_custom_entry_fee_input = true;
								} else {
									_state.gas = event.target.value;
								}

								this.setState({
									...this.state,
									..._state,
								});
							}}
							className="custom-select custom-select-sm my-2">
							<option value="fast">Fast</option>
							<option value="fastest">Fastest</option>
							<option value="ultra">Ultra Fast</option>
							<option value="custom">Custom</option>
						</select>
					</FormGroup>
				)}
			</React.Fragment>
		);
	};

	renderOrderEntryModal = () => {
		const { create_exit_order, modal_enter, selected_symbol, tokens } = this.state;
		const label = R.pathOr("", [selected_symbol, "label"], tokens);
		const ticker = R.pathOr("", [selected_symbol, "ticker"], tokens);

		return (
			<React.Fragment>
				{modal_enter && (
					<Modal size="md" open={modal_enter} toggle={this.handleModal}>
						<ModalHeader>
							<p className="checkout-header panel-header text-sm-left mb-0 p-0 position-relative">
								<span className="mr-2">CREATE ORDER (BUY)</span>
								<span className="badge badge-success position-absolute">{ticker}</span>
							</p>
						</ModalHeader>
						<ModalBody className="position-relative">
							<div className="order-form-container mt-2 mb-3 px-1">{this.renderOrderEntryForm()}</div>
							<ButtonGroup className="mt-2 w-100">
								<Button
									className="modal-button modal-cancel"
									onClick={(event) => {
										const _state = {};

										this.setState(
											{
												...this.state,
												..._state,
											},
											() => {
												this.handleModal(event);
											}
										);
									}}>
									CANCEL
								</Button>
								<Button
									className="modal-button modal-apply"
									disabled={this.state.slider_position_entry_value <= 0}
									onClick={() => {
										const { gas, uniswapV1 } = this.state;

										const _state = {
											orders: [...this.state.orders],
											watchlist: [...this.state.watchlist],
										};

										const id = "ord" + new Date().getTime();

										_state.active_order = id;
										_state.orders.push({
											gas: gas,
											id: id,
											label: label,
											order: "open",
											position: this.state.crypto_price > 0 ? this.state.slider_position_entry_value / this.state.crypto_price : 0,
											spend_amount: this.state.order_entry_amount,
											status: "active",
											symbol: selected_symbol,
											ticker: ticker,
											timestamp: new Date().getTime(),
											total_order: this.state.position_entry_amount,
											type: "entry",
											uniswapV1: uniswapV1,
										});

										if (create_exit_order === true) {
										} else if (create_exit_order === false) {
											_state.watchlist.push(selected_symbol);
											_state.active_order = null;
											_state.active_order_price = 0;
											_state.order_entry_amount = 0;
											_state.position_entry_amount = 0;
											_state.slider_order_percentage_value = 100;
											_state.slider_order_entry_value = 0;
											_state.slider_profit_percentage_value = 0;
											_state.slider_position_entry_value = 0;
										}

										this.setState(
											{
												...this.state,
												..._state,
											},
											() => {
												if (create_exit_order === true) {
													this.handleModal(null, "enter_exit");
												} else if (create_exit_order === false) {
													this.handleModal();
													this.updatePersistedOrders();
												}
											}
										);
									}}>
									CREATE ORDER
								</Button>
							</ButtonGroup>
						</ModalBody>
					</Modal>
				)}
			</React.Fragment>
		);
	};

	renderOrderEntryExitForm = () => {
		const {
			active_order,
			active_order_price,
			crypto_price,
			currency,
			gas,
			orders,
			position_entry_amount,
			selected_symbol,
			slider_order_percentage_value,
			slider_profit_percentage_value,
			toggle_custom_exit_fee_input,
			toggle_position_entry_exit_slider,
			toggle_order_exit_slider,
			tokens,
		} = this.state;

		const label = R.pathOr("", [selected_symbol, "label"], tokens);

		const isOrder = R.pathEq(["id"], active_order);
		const isEntry = R.pathEq(["type"], "entry");
		const matchedOrders = R.filter(R.both(isOrder, isEntry), orders);

		const position = R.pathOr(0, [0, "position"], matchedOrders);
		const total_order = R.pathOr(0, [0, "total_order"], matchedOrders);
		const current_order = position * total_order;
		const total_active_order = total_order * active_order_price;
		const price_change = current_order - total_active_order;

		let price_change_element;

		if (price_change > 0) {
			price_change_element = <span className="badge badge-danger px-1 py-1">&ndash;{(Math.abs(price_change / current_order) * 100).toFixed(2)}%</span>;
		} else if (price_change === 0) {
			price_change_element = <span className="badge badge-light px-1 py-1">{(Math.abs(price_change / current_order) * 100).toFixed(2)}%</span>;
		} else {
			price_change_element = <span className="badge badge-success px-1 py-1">&#43;{(Math.abs(price_change / current_order) * 100).toFixed(2)}%</span>;
		}

		return (
			<React.Fragment>
				<FormGroup className="order-form-group pb-4 px-1">
					<div className="d-flex flex-wrap justify-content-between position-relative">
						<h6 className="w-50 text-left">
							<span className="order-label">ORDER COST: {current_order.toFixed(6)}&nbsp;ETH</span>
						</h6>
						<h6 className="w-50 text-right position-relative">
							<span className="order-label">ORDER VALUE: {total_active_order.toFixed(6)}&nbsp;ETH</span>
							<span className="value-change position-absolute">{price_change_element}</span>
						</h6>
						<h6 className="w-100 text-center">
							<span className="order-label">
								PROJECTED PROFIT:&nbsp;
								{(current_order + current_order * (slider_profit_percentage_value / 100)).toFixed(6)}
								&nbsp;ETH
							</span>
						</h6>
						<div
							className="keypad-icon position-absolute"
							onClick={(event) => {
								const _state = {};

								_state.toggle_order_exit_slider = !this.state.toggle_order_exit_slider;

								this.setState({
									...this.state,
									..._state,
								});
							}}>
							{!toggle_order_exit_slider && (
								<img
									alt=""
									src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDIwIDIwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0wIDZjMC0xLjEuOS0yIDItMmgxNmEyIDIgMCAwIDEgMiAydjhhMiAyIDAgMCAxLTIgMkgyYTIgMiAwIDAgMS0yLTJWNnptMiAwdjJoMlY2SDJ6bTEgM3YyaDJWOUgzem0tMSAzdjJoMnYtMkgyem0zIDB2MmgxMHYtMkg1em0xMSAwdjJoMnYtMmgtMnpNNiA5djJoMlY5SDZ6bTMgMHYyaDJWOUg5em0zIDB2MmgyVjloLTJ6bTMgMHYyaDJWOWgtMnpNNSA2djJoMlY2SDV6bTMgMHYyaDJWNkg4em0zIDB2MmgyVjZoLTJ6bTMgMHYyaDRWNmgtNHoiLz48L3N2Zz4="
								/>
							)}
							{toggle_order_exit_slider && (
								<img
									alt=""
									className="slider-icon"
									src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDMwIDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlLz48ZyBkYXRhLW5hbWU9IkxheWVyIDIiIGlkPSJMYXllcl8yIj48ZyBpZD0iSW50ZXJmYWNlLVNvbGlkIj48ZyBpZD0iaW50ZXJmYWNlLXNvbGlkLXNsaWRlciI+PHBhdGggZD0iTTExLjAzMTI1LDNIMUExLDEsMCwwLDAsMSw1SDExLjAzMTI1YTEsMSwwLDAsMCwwLTJaIi8+PHBhdGggZD0iTTI5LDNIMjdhMSwxLDAsMCwwLDAsMmgyYTEsMSwwLDAsMCwwLTJaIi8+PGNpcmNsZSBjeD0iMTkiIGN5PSI0IiByPSI0Ii8+PC9nPjwvZz48L2c+PC9zdmc+"
								/>
							)}
						</div>
					</div>
					{!toggle_order_exit_slider && (
						<Slider
							className="order-slider exit mx-3"
							connect={[true, false]}
							onSlide={this.updateProfitPercentage}
							pips={{
								density: 100,
								mode: "steps",
								stepped: false,
								format: wNumb({
									suffix: "%",
								}),
							}}
							range={{ min: 1, max: 100 }}
							start={[slider_profit_percentage_value]}
						/>
					)}
					{toggle_order_exit_slider && (
						<FormInput
							className="order-amount-input mb-2 mt-4"
							type="number"
							placeholder="Enter profit limit required to exit. Example: 7.5"
							onKeyUp={(event) => {
								if (Number.isNaN(event.target.value) === false) {
									this.updateProfitPercentage([event.target.value]);
								} else {
									this.updateProfitPercentage([0]);
								}
							}}
							onChange={(event) => {
								if (Number.isNaN(event.target.value) === false) {
									this.updateProfitPercentage([event.target.value]);
								} else {
									this.updateProfitPercentage([0]);
								}
							}}
						/>
					)}
				</FormGroup>
				<FormGroup className="order-form-group pb-4 px-1">
					<div className="d-flex justify-content-between position-relative">
						<h6 htmlFor="entry-position-input">
							SELLING&nbsp;{(position_entry_amount * (slider_order_percentage_value / 100)).toFixed(6)}&nbsp;{label}&nbsp;@&nbsp;$
							{(position * crypto_price + position * crypto_price * (slider_profit_percentage_value / 100)).toFixed(6)}&nbsp;${currency.toUpperCase()}
						</h6>
						<div
							className="keypad-icon disabled position-absolute"
							onClick={(event) => {
								return false; // disable toggle for now

								// const _state = {};
								//
								// _state.toggle_position_entry_exit_slider = !this.state.toggle_position_entry_exit_slider;
								//
								// this.setState({
								// 	...this.state,
								// 	..._state,
								// });
							}}>
							{!toggle_position_entry_exit_slider && (
								<img
									alt=""
									src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDIwIDIwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0wIDZjMC0xLjEuOS0yIDItMmgxNmEyIDIgMCAwIDEgMiAydjhhMiAyIDAgMCAxLTIgMkgyYTIgMiAwIDAgMS0yLTJWNnptMiAwdjJoMlY2SDJ6bTEgM3YyaDJWOUgzem0tMSAzdjJoMnYtMkgyem0zIDB2MmgxMHYtMkg1em0xMSAwdjJoMnYtMmgtMnpNNiA5djJoMlY5SDZ6bTMgMHYyaDJWOUg5em0zIDB2MmgyVjloLTJ6bTMgMHYyaDJWOWgtMnpNNSA2djJoMlY2SDV6bTMgMHYyaDJWNkg4em0zIDB2MmgyVjZoLTJ6bTMgMHYyaDRWNmgtNHoiLz48L3N2Zz4="
								/>
							)}
							{toggle_position_entry_exit_slider && (
								<img
									alt=""
									className="slider-icon"
									src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDMwIDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlLz48ZyBkYXRhLW5hbWU9IkxheWVyIDIiIGlkPSJMYXllcl8yIj48ZyBpZD0iSW50ZXJmYWNlLVNvbGlkIj48ZyBpZD0iaW50ZXJmYWNlLXNvbGlkLXNsaWRlciI+PHBhdGggZD0iTTExLjAzMTI1LDNIMUExLDEsMCwwLDAsMSw1SDExLjAzMTI1YTEsMSwwLDAsMCwwLTJaIi8+PHBhdGggZD0iTTI5LDNIMjdhMSwxLDAsMCwwLDAsMmgyYTEsMSwwLDAsMCwwLTJaIi8+PGNpcmNsZSBjeD0iMTkiIGN5PSI0IiByPSI0Ii8+PC9nPjwvZz48L2c+PC9zdmc+"
								/>
							)}
						</div>
					</div>
					{!toggle_position_entry_exit_slider && (
						<Slider
							disabled={true}
							className="entry-position-slider exit mx-3"
							connect={[true, false]}
							onSlide={this.updateOrderPercentage}
							pips={{
								density: 100,
								mode: "steps",
								stepped: false,
								format: wNumb({
									suffix: "%",
								}),
							}}
							range={{ min: 1, max: 100 }}
							start={[slider_order_percentage_value]}
						/>
					)}
					{toggle_position_entry_exit_slider && (
						<FormInput
							className="entry-position-input mb-2 mt-4"
							type="number"
							placeholder="Enter percentage of order to be sold. Example: 10.5"
							onKeyUp={(event) => {
								if (Number.isNaN(event.target.value) === false) {
									this.updateOrderPercentage([event.target.value]);
								} else {
									this.updateOrderPercentage([0]);
								}
							}}
							onChange={(event) => {
								if (Number.isNaN(event.target.value) === false) {
									this.updateOrderPercentage([event.target.value]);
								} else {
									this.updateOrderPercentage([0]);
								}
							}}
						/>
					)}
				</FormGroup>
				{toggle_custom_exit_fee_input && (
					<FormGroup className="order-form-group pb-0 mb-0 px-1">
						<div className="d-flex justify-content-between position-relative">
							<h6 htmlFor="exit-transaction-fee-select">TRANSACTION FEE</h6>
						</div>
						{this.renderCustomTransactionFee(active_order, "exit")}
					</FormGroup>
				)}
				{!toggle_custom_exit_fee_input && (
					<FormGroup className="order-form-group pb-0 mb-0 px-1">
						<div className="d-flex justify-content-between position-relative">
							<h6 htmlFor="exit-transaction-fee-select">TRANSACTION FEE</h6>
						</div>
						<select
							id="exit-transaction-fee-select"
							defaultValue={typeof gas === "string" ? gas : "custom"}
							onChange={(event) => {
								const _state = {};

								if (event.target.value === "custom") {
									_state.toggle_custom_exit_fee_input = true;
								} else {
									_state.gas = event.target.value;
								}

								this.setState({
									...this.state,
									..._state,
								});
							}}
							className="custom-select custom-select-sm my-2">
							<option value="fast">Fast</option>
							<option value="fastest">Fastest</option>
							<option value="ultra">Ultra Fast</option>
							<option value="custom">Custom</option>
						</select>
					</FormGroup>
				)}
			</React.Fragment>
		);
	};

	renderOrderEntryExitModal = () => {
		const { modal_enter_exit, selected_symbol, tokens } = this.state;
		const label = R.pathOr("", [selected_symbol, "label"], tokens);
		const ticker = R.pathOr("", [selected_symbol, "ticker"], tokens);

		return (
			<React.Fragment>
				{modal_enter_exit && (
					<Modal size="md" open={modal_enter_exit} toggle={this.handleModal}>
						<ModalHeader>
							<p className="checkout-header panel-header text-sm-left mb-0 p-0 position-relative">
								<span className="mr-2">CREATE ORDER (EXIT)</span>
								<span className="badge badge-success position-absolute">{ticker}</span>
							</p>
						</ModalHeader>
						<ModalBody className="position-relative">
							<div className="order-form-container mt-2 mb-3 px-1">{this.renderOrderEntryExitForm()}</div>
							<ButtonGroup className="mt-2 w-100">
								<Button
									className="modal-button modal-cancel"
									onClick={(event) => {
										const _state = {};

										this.setState(
											{
												...this.state,
												..._state,
											},
											() => {
												this.handleModal(event);
											}
										);
									}}>
									CANCEL
								</Button>
								<Button
									className="modal-button modal-apply"
									onClick={() => {
										const { active_order, gas, orders, slider_profit_percentage_value } = this.state;

										const isOrder = R.pathEq(["id"], active_order);
										const isEntry = R.pathEq(["type"], "entry");
										const matchedOrders = R.filter(R.both(isOrder, isEntry), orders);
										const position = R.pathOr(0, [0, "position"], matchedOrders);
										const total_order = R.pathOr(0, [0, "total_order"], matchedOrders);

										const _state = {
											orders: [...this.state.orders],
											watchlist: [...this.state.watchlist],
										};

										_state.orders.push({
											gas: gas,
											id: active_order,
											label: label,
											order: "open",
											position: this.state.crypto_price > 0 ? position + position * (slider_profit_percentage_value / 100) : 0,
											spend_amount: this.state.order_entry_amount,
											status: "active",
											symbol: selected_symbol,
											timestamp: new Date().getTime(),
											ticker: ticker,
											total_order: total_order,
											type: "exit",
										});

										_state.watchlist.push(selected_symbol);
										_state.active_order = null;
										_state.active_order_price = 0;
										_state.order_entry_amount = 0;
										_state.position_entry_amount = 0;
										_state.slider_order_percentage_value = 100;
										_state.slider_order_entry_value = 0;
										_state.slider_profit_percentage_value = 0;
										_state.slider_position_entry_value = 0;

										this.setState(
											{
												...this.state,
												..._state,
											},
											() => {
												this.handleModal();
												this.updatePersistedOrders();
											}
										);
									}}>
									CREATE ORDER
								</Button>
							</ButtonGroup>
						</ModalBody>
					</Modal>
				)}
			</React.Fragment>
		);
	};

	renderOrderExitForm = () => {
		const {
			active_order,
			currency,
			gas,
			order_exit_amount,
			price_data,
			selected_symbol,
			selected_symbol_balance,
			slider_order_exit_value,
			slider_position_exit_value,
			toggle_custom_exit_fee_input,
			toggle_position_exit_slider,
			toggle_order_exit_label,
			toggle_order_exit_slider,
			tokens,
		} = this.state;

		const label = R.pathOr("", [selected_symbol, "label"], tokens);
		const ticker = R.pathOr("", [selected_symbol, "ticker"], tokens);

		const { high, low } = price_data;
		const _low = +low.toFixed(6);
		const _high = +high.toFixed(6);

		const filterPips = (value, type) => {
			if (type === 1) {
				return value === _low || value === _high ? 1 : 0;
			}

			return 0;
		};

		return (
			<React.Fragment>
				<FormGroup className="order-form-group pb-4 px-1">
					<div className="d-flex justify-content-between position-relative">
						<h6 className="w-50">
							<span className="order-label">
								WALLET: {selected_symbol_balance.toFixed(6)}&nbsp;{ticker}
							</span>
						</h6>
						<div className="d-block-full w-50">
							{!toggle_order_exit_label && (
								<h6 className="text-right" onClick={this.toggleLabelExit}>
									<span className="order-label">
										ORDER: {order_exit_amount.toFixed(6)}&nbsp;{ticker}
									</span>
								</h6>
							)}
							{toggle_order_exit_label && (
								<div className="d-block-full">
									<h6 className="d-flex flex-justify-between">
										<span className="order-label mr-2" onClick={this.toggleLabelExit}>
											ORDER:
										</span>
										<FormInput className="w-75" size="sm" placeholder={order_exit_amount.toFixed(8)} type="number" />
									</h6>
								</div>
							)}
						</div>
						<div
							className="keypad-icon position-absolute"
							onClick={(event) => {
								const _state = {};

								_state.toggle_order_exit_slider = !this.state.toggle_order_exit_slider;

								this.setState({
									...this.state,
									..._state,
								});
							}}>
							{!toggle_order_exit_slider && (
								<img
									alt=""
									src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDIwIDIwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0wIDZjMC0xLjEuOS0yIDItMmgxNmEyIDIgMCAwIDEgMiAydjhhMiAyIDAgMCAxLTIgMkgyYTIgMiAwIDAgMS0yLTJWNnptMiAwdjJoMlY2SDJ6bTEgM3YyaDJWOUgzem0tMSAzdjJoMnYtMkgyem0zIDB2MmgxMHYtMkg1em0xMSAwdjJoMnYtMmgtMnpNNiA5djJoMlY5SDZ6bTMgMHYyaDJWOUg5em0zIDB2MmgyVjloLTJ6bTMgMHYyaDJWOWgtMnpNNSA2djJoMlY2SDV6bTMgMHYyaDJWNkg4em0zIDB2MmgyVjZoLTJ6bTMgMHYyaDRWNmgtNHoiLz48L3N2Zz4="
								/>
							)}
							{toggle_order_exit_slider && (
								<img
									alt=""
									className="slider-icon"
									src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDMwIDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlLz48ZyBkYXRhLW5hbWU9IkxheWVyIDIiIGlkPSJMYXllcl8yIj48ZyBpZD0iSW50ZXJmYWNlLVNvbGlkIj48ZyBpZD0iaW50ZXJmYWNlLXNvbGlkLXNsaWRlciI+PHBhdGggZD0iTTExLjAzMTI1LDNIMUExLDEsMCwwLDAsMSw1SDExLjAzMTI1YTEsMSwwLDAsMCwwLTJaIi8+PHBhdGggZD0iTTI5LDNIMjdhMSwxLDAsMCwwLDAsMmgyYTEsMSwwLDAsMCwwLTJaIi8+PGNpcmNsZSBjeD0iMTkiIGN5PSI0IiByPSI0Ii8+PC9nPjwvZz48L2c+PC9zdmc+"
								/>
							)}
						</div>
					</div>
					{!toggle_order_exit_slider && (
						<Slider
							className="order-slider mx-3"
							connect={[true, false]}
							onSlide={this.updateExitAmount}
							pips={{
								density: 100,
								mode: "steps",
								stepped: false,
								format: wNumb({
									suffix: "%",
								}),
							}}
							range={{ min: 1, max: 100 }}
							start={[slider_order_exit_value]}
						/>
					)}
					{toggle_order_exit_slider && (
						<FormInput
							className="order-amount-input mb-2 mt-4"
							type="number"
							placeholder={"Enter order amount in " + ticker + ". Example: 1.00"}
							onKeyUp={(event) => {
								if (Number.isNaN(event.target.value) === false) {
									const _state = {};

									_state.order_exit_amount = +event.target.value;

									this.setState({
										...this.state,
										..._state,
									});
								} else {
									this.updateExitAmount([1]);
								}
							}}
							onChange={(event) => {
								if (Number.isNaN(event.target.value) === false) {
									const _state = {};

									_state.order_exit_amount = +event.target.value;

									this.setState({
										...this.state,
										..._state,
									});
								} else {
									this.updateExitAmount([1]);
								}
							}}
						/>
					)}
				</FormGroup>
				<FormGroup className="order-form-group pb-4 px-1">
					<div className="d-flex justify-content-between position-relative">
						<h6>
							SELLING&nbsp;{order_exit_amount.toFixed(6)} {label}&nbsp;@&nbsp;${slider_position_exit_value}&nbsp;{currency.toUpperCase()}
						</h6>
						<div
							className="keypad-icon position-absolute"
							onClick={(event) => {
								const _state = {};

								_state.toggle_position_exit_slider = !this.state.toggle_position_exit_slider;

								this.setState({
									...this.state,
									..._state,
								});
							}}>
							{!toggle_position_exit_slider && (
								<img
									alt=""
									src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDIwIDIwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0wIDZjMC0xLjEuOS0yIDItMmgxNmEyIDIgMCAwIDEgMiAydjhhMiAyIDAgMCAxLTIgMkgyYTIgMiAwIDAgMS0yLTJWNnptMiAwdjJoMlY2SDJ6bTEgM3YyaDJWOUgzem0tMSAzdjJoMnYtMkgyem0zIDB2MmgxMHYtMkg1em0xMSAwdjJoMnYtMmgtMnpNNiA5djJoMlY5SDZ6bTMgMHYyaDJWOUg5em0zIDB2MmgyVjloLTJ6bTMgMHYyaDJWOWgtMnpNNSA2djJoMlY2SDV6bTMgMHYyaDJWNkg4em0zIDB2MmgyVjZoLTJ6bTMgMHYyaDRWNmgtNHoiLz48L3N2Zz4="
								/>
							)}
							{toggle_position_exit_slider && (
								<img
									alt=""
									className="slider-icon"
									src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDMwIDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlLz48ZyBkYXRhLW5hbWU9IkxheWVyIDIiIGlkPSJMYXllcl8yIj48ZyBpZD0iSW50ZXJmYWNlLVNvbGlkIj48ZyBpZD0iaW50ZXJmYWNlLXNvbGlkLXNsaWRlciI+PHBhdGggZD0iTTExLjAzMTI1LDNIMUExLDEsMCwwLDAsMSw1SDExLjAzMTI1YTEsMSwwLDAsMCwwLTJaIi8+PHBhdGggZD0iTTI5LDNIMjdhMSwxLDAsMCwwLDAsMmgyYTEsMSwwLDAsMCwwLTJaIi8+PGNpcmNsZSBjeD0iMTkiIGN5PSI0IiByPSI0Ii8+PC9nPjwvZz48L2c+PC9zdmc+"
								/>
							)}
						</div>
					</div>
					{!toggle_position_exit_slider && (
						<Slider
							className="entry-position-slider exit exit-only mx-3"
							connect={[true, false]}
							onSlide={this.updateExitPrice}
							pips={{
								density: 100,
								mode: "steps",
								filter: filterPips,
								stepped: false,
								format: wNumb({ decimals: 6 }),
							}}
							range={{ min: [_low], max: [_high] }}
							start={[slider_position_exit_value]}
						/>
					)}
					{toggle_position_exit_slider && (
						<FormInput
							className="exit-position-input mb-2 mt-4"
							type="number"
							placeholder="Enter percentage of order to be sold. Example: 10.5"
							onKeyUp={(event) => {
								if (Number.isNaN(event.target.value) === false) {
									this.updateExitPrice([event.target.value]);
								} else {
									this.updateExitPrice([0]);
								}
							}}
							onChange={(event) => {
								if (Number.isNaN(event.target.value) === false) {
									this.updateExitPrice([event.target.value]);
								} else {
									this.updateExitPrice([0]);
								}
							}}
						/>
					)}
				</FormGroup>
				{toggle_custom_exit_fee_input && (
					<FormGroup className="order-form-group pb-0 mb-0 px-1">
						<div className="d-flex justify-content-between position-relative">
							<h6>TRANSACTION FEE</h6>
						</div>
						{this.renderCustomTransactionFee(active_order, "exit")}
					</FormGroup>
				)}
				{!toggle_custom_exit_fee_input && (
					<FormGroup className="order-form-group pb-0 mb-0 px-1">
						<div className="d-flex justify-content-between position-relative">
							<h6>TRANSACTION FEE</h6>
						</div>
						<select
							id="exit-transaction-fee-select"
							defaultValue={typeof gas === "string" ? gas : "custom"}
							onChange={(event) => {
								const _state = {};

								if (event.target.value === "custom") {
									_state.toggle_custom_exit_fee_input = true;
								} else {
									_state.gas = event.target.value;
								}

								this.setState({
									...this.state,
									..._state,
								});
							}}
							className="custom-select custom-select-sm my-2">
							<option value="fast">Fast</option>
							<option value="fastest">Fastest</option>
							<option value="ultra">Ultra Fast</option>
							<option value="custom">Custom</option>
						</select>
					</FormGroup>
				)}
			</React.Fragment>
		);
	};

	renderOrderExitModal = () => {
		const { modal_exit, selected_symbol, tokens } = this.state;
		const label = R.pathOr("", [selected_symbol, "label"], tokens);
		const ticker = R.pathOr("", [selected_symbol, "ticker"], tokens);

		return (
			<React.Fragment>
				{modal_exit && (
					<Modal size="md" open={modal_exit} toggle={this.handleModal}>
						<ModalHeader>
							<p className="checkout-header panel-header text-sm-left mb-0 p-0 position-relative">
								<span className="mr-2">CREATE ORDER (SELL)</span>
								<span className="badge badge-success position-absolute">{ticker}</span>
							</p>
						</ModalHeader>
						<ModalBody className="position-relative">
							<div className="order-form-container mt-2 mb-3 px-1">{this.renderOrderExitForm()}</div>
							<ButtonGroup className="mt-2 w-100">
								<Button
									className="modal-button modal-cancel"
									onClick={(event) => {
										const _state = {};

										this.setState(
											{
												...this.state,
												..._state,
											},
											() => {
												this.handleModal(event);
											}
										);
									}}>
									CANCEL
								</Button>
								<Button
									className="modal-button modal-apply"
									onClick={() => {
										const { gas, order_exit_amount, slider_position_exit_value } = this.state;

										const _state = {
											orders: [...this.state.orders],
											watchlist: [...this.state.watchlist],
										};

										_state.orders.push({
											gas: gas,
											id: "ord" + new Date().getTime(),
											label: label,
											order: "open",
											position: slider_position_exit_value / this.state.crypto_price,
											spend_amount: (slider_position_exit_value / this.state.crypto_price) * order_exit_amount,
											status: "active",
											symbol: selected_symbol,
											timestamp: new Date().getTime(),
											ticker: ticker,
											total_order: order_exit_amount,
											type: "exit",
										});

										_state.watchlist.push(selected_symbol);
										_state.active_order = null;
										_state.active_order_price = 0;
										_state.order_entry_amount = 0;
										_state.position_entry_amount = 0;
										_state.slider_order_percentage_value = 100;
										_state.slider_order_entry_value = 0;
										_state.slider_profit_percentage_value = 0;
										_state.slider_position_entry_value = 0;

										this.setState(
											{
												...this.state,
												..._state,
											},
											() => {
												this.handleModal();
												this.updatePersistedOrders();
											}
										);
									}}>
									CREATE ORDER
								</Button>
							</ButtonGroup>
						</ModalBody>
					</Modal>
				)}
			</React.Fragment>
		);
	};

	renderOrderFilter = () => {
		return (
			<React.Fragment>
				<Row className="mb-3">
					<Col sm={12} md={6} lg={6} className="filters-group-wrap mb-1 mb-md-0">
						<div className="filters-group">
							<ButtonGroup className="filter-options d-flex justify-content-center">
								<Button data-group="city">Entry/Exit</Button>
								<Button data-group="nature">Closed</Button>
								<Button data-group="animal">Buy</Button>
								<Button data-group="city">Sell</Button>
							</ButtonGroup>
						</div>
					</Col>
					<Col sm={12} md={6} lg={6} className="filters-group-wrap mt-1 mt-md-0">
						<fieldset className="filters-group">
							<ButtonGroup className="sort-options d-flex justify-content-center">
								<Button>
									<input type="radio" name="sort-value" value="dom" /> Default
								</Button>
								<Button>
									<input type="radio" name="sort-value" value="title" /> Symbol
								</Button>
								<Button>
									<input type="radio" name="sort-value" value="date-created" /> Date
								</Button>
							</ButtonGroup>
						</fieldset>
					</Col>
				</Row>
				{/*<Row>
					<Col sm={12} md={12} lg={12}>
						<div id="grid" className="row my-shuffle-container">
							<figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["nature"]' data-date-created="2017-04-30" data-title="Lake Walchen">
								<div className="picture-item__inner">
									<div className="aspect aspect--16x9">
										<div className="aspect__inner">
											<img
												src="https://images.unsplash.com/photo-1493585552824-131927c85da2?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=6ef0f8984525fc4500d43ffa53fe8190"
												srcSet="https://images.unsplash.com/photo-1493585552824-131927c85da2?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=6ef0f8984525fc4500d43ffa53fe8190 1x, https://images.unsplash.com/photo-1493585552824-131927c85da2?ixlib=rb-0.3.5&auto=format&q=55&fm=jpg&dpr=2&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=6ef0f8984525fc4500d43ffa53fe8190 2x"
												alt="A deep blue lake sits in the middle of vast hills covered with evergreen trees"
											/>
										</div>
									</div>
									<div className="picture-item__details">
										<figcaption className="picture-item__title">
											<a href="https://unsplash.com/photos/zshyCr6HGw0" target="_blank" rel="noopener">
												Lake Walchen
											</a>
										</figcaption>
										<p className="picture-item__tags hidden@xs">nature</p>
									</div>
								</div>
							</figure>
							<figure className="col-3@xs col-8@sm col-6@md picture-item picture-item--overlay" data-groups='["city"]' data-date-created="2016-07-01" data-title="Golden Gate Bridge">
								<div className="picture-item__inner">
									<img
										src="https://images.unsplash.com/photo-1467348733814-f93fc480bec6?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=584&h=329&fit=crop&s=2590c736835ec6555e952e19bb37f06e"
										srcSet="https://images.unsplash.com/photo-1467348733814-f93fc480bec6?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=584&h=329&fit=crop&s=2590c736835ec6555e952e19bb37f06e 1x, https://images.unsplash.com/photo-1467348733814-f93fc480bec6?ixlib=rb-0.3.5&auto=format&q=55&fm=jpg&dpr=2&crop=entropy&cs=tinysrgb&w=584&h=329&fit=crop&s=2590c736835ec6555e952e19bb37f06e 2x"
										alt="Looking down over one of the pillars of the Golden Gate Bridge to the roadside and water below"
									/>
									<div className="picture-item__details">
										<figcaption className="picture-item__title">
											<a href="https://unsplash.com/photos/RRNbMiPmTZY" target="_blank" rel="noopener">
												Golden Gate Bridge
											</a>
										</figcaption>
										<p className="picture-item__tags hidden@xs">city</p>
									</div>
								</div>
							</figure>
							<figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["animal"]' data-date-created="2016-08-12" data-title="Crocodile">
								<div className="picture-item__inner">
									<div className="aspect aspect--16x9">
										<div className="aspect__inner">
											<img
												src="https://images.unsplash.com/photo-1471005197911-88e9d4a7834d?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=bd8b952c4c983d4bde5e2018c90c9124"
												srcSet="https://images.unsplash.com/photo-1471005197911-88e9d4a7834d?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=bd8b952c4c983d4bde5e2018c90c9124 1x, https://images.unsplash.com/photo-1471005197911-88e9d4a7834d?ixlib=rb-0.3.5&auto=format&q=55&fm=jpg&dpr=2&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=bd8b952c4c983d4bde5e2018c90c9124 2x"
												alt="A close, profile view of a crocodile looking directly into the camera"
											/>
										</div>
									</div>
									<div className="picture-item__details">
										<figcaption className="picture-item__title">
											<a href="https://unsplash.com/photos/YOX8ZMTo7hk" target="_blank" rel="noopener">
												Crocodile
											</a>
										</figcaption>
										<p className="picture-item__tags hidden@xs">animal</p>
									</div>
								</div>
							</figure>
							<figure className="col-3@xs col-4@sm col-3@md picture-item picture-item--h2" data-groups='["space"]' data-date-created="2016-03-07" data-title="SpaceX">
								<div className="picture-item__inner">
									<div className="aspect aspect--16x9">
										<div className="aspect__inner">
											<img
												src="https://images.unsplash.com/photo-1457364559154-aa2644600ebb?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=3d0e3e8d72fc5667fd9fbe354e80957b"
												srcSet="https://images.unsplash.com/photo-1457364559154-aa2644600ebb?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=3d0e3e8d72fc5667fd9fbe354e80957b 1x, https://images.unsplash.com/photo-1457364559154-aa2644600ebb?ixlib=rb-0.3.5&auto=format&q=55&fm=jpg&dpr=2&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=3d0e3e8d72fc5667fd9fbe354e80957b 2x"
												alt="SpaceX launches a Falcon 9 rocket from Cape Canaveral Air Force Station"
											/>
										</div>
									</div>
									<div className="picture-item__details">
										<figcaption className="picture-item__title">
											<a href="https://unsplash.com/photos/GDdRP7U5ct0" target="_blank" rel="noopener">
												SpaceX
											</a>
										</figcaption>
										<p className="picture-item__tags hidden@xs">space</p>
									</div>
									<p className="picture-item__description">SpaceX launches a Falcon 9 rocket from Cape Canaveral Air Force Station</p>
								</div>
							</figure>
							<figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["city"]' data-date-created="2016-06-09" data-title="Crossroads">
								<div className="picture-item__inner">
									<div className="aspect aspect--16x9">
										<div className="aspect__inner">
											<img
												src="https://images.unsplash.com/photo-1465447142348-e9952c393450?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=7d97e22d36a9a73beb639a936e6774e9"
												srcSet="https://images.unsplash.com/photo-1465447142348-e9952c393450?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=7d97e22d36a9a73beb639a936e6774e9 1x, https://images.unsplash.com/photo-1465447142348-e9952c393450?ixlib=rb-0.3.5&auto=format&q=55&fm=jpg&dpr=2&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=7d97e22d36a9a73beb639a936e6774e9 2x"
												alt="A multi-level highway stack interchange in Puxi, Shanghai"
											/>
										</div>
									</div>
									<div className="picture-item__details">
										<figcaption className="picture-item__title">
											<a href="https://unsplash.com/photos/7nrsVjvALnA" target="_blank" rel="noopener">
												Crossroads
											</a>
										</figcaption>
										<p className="picture-item__tags hidden@xs">city</p>
									</div>
								</div>
							</figure>
							<figure className="col-6@xs col-8@sm col-6@md picture-item picture-item--overlay" data-groups='["space","nature"]' data-date-created="2016-06-29" data-title="Milky Way">
								<div className="picture-item__inner">
									<img
										src="https://images.unsplash.com/photo-1467173572719-f14b9fb86e5f?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=584&h=329&fit=crop&s=e641d6b3c4c2c967e80e998d02a4d03b"
										srcSet="https://images.unsplash.com/photo-1467173572719-f14b9fb86e5f?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=584&h=329&fit=crop&s=e641d6b3c4c2c967e80e998d02a4d03b 1x, https://images.unsplash.com/photo-1467173572719-f14b9fb86e5f?ixlib=rb-0.3.5&auto=format&q=55&fm=jpg&dpr=2&crop=entropy&cs=tinysrgb&w=584&h=329&fit=crop&s=e641d6b3c4c2c967e80e998d02a4d03b 2x"
										alt="Dimly lit mountains give way to a starry night showing the Milky Way"
									/>
									<div className="picture-item__details">
										<figcaption className="picture-item__title">
											<a href="https://unsplash.com/photos/_4Ib-a8g9aA" target="_blank" rel="noopener">
												Milky Way
											</a>
										</figcaption>
										<p className="picture-item__tags hidden@xs">space, nature</p>
									</div>
								</div>
							</figure>
							<figure className="col-6@xs col-8@sm col-6@md picture-item picture-item--h2" data-groups='["space"]' data-date-created="2015-11-06" data-title="Earth">
								<div className="picture-item__inner">
									<div className="aspect aspect--16x9">
										<div className="aspect__inner">
											<img
												src="https://images.unsplash.com/photo-1446776811953-b23d57bd21aa?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=584&h=329&fit=crop&s=f4856588634def31d5885dc396fe9a2e"
												srcSet="https://images.unsplash.com/photo-1446776811953-b23d57bd21aa?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=584&h=329&fit=crop&s=f4856588634def31d5885dc396fe9a2e 1x, https://images.unsplash.com/photo-1446776811953-b23d57bd21aa?ixlib=rb-0.3.5&auto=format&q=55&fm=jpg&dpr=2&crop=entropy&cs=tinysrgb&w=584&h=329&fit=crop&s=f4856588634def31d5885dc396fe9a2e 2x"
												alt="NASA Satellite view of Earth"
											/>
										</div>
									</div>
									<div className="picture-item__details">
										<figcaption className="picture-item__title">
											<a href="https://unsplash.com/photos/yZygONrUBe8" target="_blank" rel="noopener">
												Earth
											</a>
										</figcaption>
										<p className="picture-item__tags hidden@xs">space</p>
									</div>
									<p className="picture-item__description">NASA Satellite view of Earth</p>
								</div>
							</figure>
							<figure className="col-3@xs col-4@sm col-3@md picture-item picture-item--h2" data-groups='["animal"]' data-date-created="2015-07-23" data-title="Turtle">
								<div className="picture-item__inner">
									<div className="aspect aspect--16x9">
										<div className="aspect__inner">
											<img
												src="https://images.unsplash.com/photo-1437622368342-7a3d73a34c8f?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=bc4e1180b6b8789d38c614edc8d0dd01"
												srcSet="https://images.unsplash.com/photo-1437622368342-7a3d73a34c8f?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=bc4e1180b6b8789d38c614edc8d0dd01 1x, https://images.unsplash.com/photo-1437622368342-7a3d73a34c8f?ixlib=rb-0.3.5&auto=format&q=55&fm=jpg&dpr=2&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=bc4e1180b6b8789d38c614edc8d0dd01 2x"
												alt="A close up of a turtle underwater"
											/>
										</div>
									</div>
									<div className="picture-item__details">
										<figcaption className="picture-item__title">
											<a href="https://unsplash.com/photos/L-2p8fapOA8" target="_blank" rel="noopener">
												Turtle
											</a>
										</figcaption>
										<p className="picture-item__tags hidden@xs">animal</p>
									</div>
									<p className="picture-item__description">A close up of a turtle underwater</p>
								</div>
							</figure>
							<figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["nature"]' data-date-created="2014-10-12" data-title="Stanley Park">
								<div className="picture-item__inner">
									<div className="aspect aspect--16x9">
										<div className="aspect__inner">
											<img
												src="https://images.unsplash.com/uploads/1413142095961484763cf/d141726c?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=6141097da144d759176d77b4024c064b"
												srcSet="https://images.unsplash.com/uploads/1413142095961484763cf/d141726c?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=6141097da144d759176d77b4024c064b 1x, https://images.unsplash.com/uploads/1413142095961484763cf/d141726c?ixlib=rb-0.3.5&auto=format&q=55&fm=jpg&dpr=2&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=6141097da144d759176d77b4024c064b 2x"
												alt="Many trees stand alonside a hill which overlooks a pedestrian path, next to the ocean at Stanley Park in Vancouver, Canada"
											/>
										</div>
									</div>
									<div className="picture-item__details">
										<figcaption className="picture-item__title">
											<a href="https://unsplash.com/photos/b-yEdfrvQ50" target="_blank" rel="noopener">
												Stanley Park
											</a>
										</figcaption>
										<p className="picture-item__tags hidden@xs">nature</p>
									</div>
								</div>
							</figure>
							<figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["animal"]' data-date-created="2017-01-12" data-title="Astronaut Cat">
								<div className="picture-item__inner">
									<div className="aspect aspect--16x9">
										<div className="aspect__inner">
											<img
												src="https://images.unsplash.com/photo-1484244233201-29892afe6a2c?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=98423596f72d9f0913a4d44f0580a34c"
												srcSet="https://images.unsplash.com/photo-1484244233201-29892afe6a2c?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=98423596f72d9f0913a4d44f0580a34c 1x, https://images.unsplash.com/photo-1484244233201-29892afe6a2c?ixlib=rb-0.3.5&auto=format&q=55&fm=jpg&dpr=2&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=98423596f72d9f0913a4d44f0580a34c 2x"
												alt="An intrigued cat sits in grass next to a flag planted in front of it with an astronaut space kitty sticker on beige fabric."
											/>
										</div>
									</div>
									<div className="picture-item__details">
										<figcaption className="picture-item__title">
											<a href="https://unsplash.com/photos/FqkBXo2Nkq0" target="_blank" rel="noopener">
												Astronaut Cat
											</a>
										</figcaption>
										<p className="picture-item__tags hidden@xs">animal</p>
									</div>
								</div>
							</figure>
							<figure className="col-3@xs col-8@sm col-6@md picture-item picture-item--overlay" data-groups='["city"]' data-date-created="2017-01-19" data-title="San Francisco">
								<div className="picture-item__inner">
									<img
										src="https://images.unsplash.com/photo-1484851050019-ca9daf7736fb?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=584&h=329&fit=crop&s=05325a7cc678f7f765cbbdcf7159ab89"
										srcSet="https://images.unsplash.com/photo-1484851050019-ca9daf7736fb?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=584&h=329&fit=crop&s=05325a7cc678f7f765cbbdcf7159ab89 1x, https://images.unsplash.com/photo-1484851050019-ca9daf7736fb?ixlib=rb-0.3.5&auto=format&q=55&fm=jpg&dpr=2&crop=entropy&cs=tinysrgb&w=584&h=329&fit=crop&s=05325a7cc678f7f765cbbdcf7159ab89 2x"
										alt="Pier 14 at night, looking towards downtown San Francisco's brightly lit buildings"
									/>
									<div className="picture-item__details">
										<figcaption className="picture-item__title">
											<a href="https://unsplash.com/photos/h3jarbNzlOg" target="_blank" rel="noopener">
												San Francisco
											</a>
										</figcaption>
										<p className="picture-item__tags hidden@xs">city</p>
									</div>
								</div>
							</figure>
							<figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["nature","city"]' data-date-created="2015-10-20" data-title="Central Park">
								<div className="picture-item__inner">
									<div className="aspect aspect--16x9">
										<div className="aspect__inner">
											<img
												src="https://images.unsplash.com/photo-1445346366695-5bf62de05412?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=1822bfd69c4021973a3d926e9294b70f"
												srcSet="https://images.unsplash.com/photo-1445346366695-5bf62de05412?ixlib=rb-0.3.5&auto=format&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=1822bfd69c4021973a3d926e9294b70f 1x, https://images.unsplash.com/photo-1445346366695-5bf62de05412?ixlib=rb-0.3.5&auto=format&q=55&fm=jpg&dpr=2&crop=entropy&cs=tinysrgb&w=284&h=160&fit=crop&s=1822bfd69c4021973a3d926e9294b70f 2x"
												alt="Looking down on central park and the surrounding builds from the Rockefellar Center"
											/>
										</div>
									</div>
									<div className="picture-item__details">
										<figcaption className="picture-item__title">
											<a href="https://unsplash.com/photos/utwYoEu9SU8" target="_blank" rel="noopener">
												Central Park
											</a>
										</figcaption>
										<p className="picture-item__tags hidden@xs">nature, city</p>
									</div>
								</div>
							</figure>
							<div className="col-1@sm col-1@xs my-sizer-element" />
						</div>
					</Col>
				</Row>*/}
			</React.Fragment>
		);
	};

	renderOrderPanel = () => {
		return (
			<Row>
				{this.renderOrderEntryModal()}
				{this.renderOrderExitModal()}
				{this.renderOrderEntryExitModal()}
				{this.renderOrderActions()}
			</Row>
		);
	};

	renderSeparator = () => {
		return (
			<Row>
				<Col sm="12" lg="12">
					<hr className="separator w-100" />
				</Col>
			</Row>
		);
	};

	renderTokenPanel = () => {
		const { account, options, private_key_persisted, price_data, selected_symbol, token_list, tokens } = this.state;
		const { current, high, low, volume } = price_data;

		const persisted_item = R.find(R.propEq("value", selected_symbol))(this.state.options);
		const selected_label = persisted_item ? persisted_item.text : "";

		let address;
		let token;
		let approved;

		if (selected_symbol && selected_symbol !== "") {
			address = R.pathOr(null, ["address"], persisted_item);
			token = R.pathOr(R.find(R.propEq("address", address))(options), [address], tokens);
			approved = R.pathOr(null, ["approved"], token);
		}

		return (
			<Row>
				<Col>
					<div className="mb-3 d-block clearfix position-relative">
						{!private_key_persisted && (
							<React.Fragment>
								<FormInput
									size="lg"
									placeholder="Private Key"
									type="password"
									className="pk-input float-left"
									onKeyUp={(event) => {
										const _state = {};

										_state.private_key = event.target.value;

										this.setState({
											...this.state,
											..._state,
										});
									}}
									onChange={(event) => {
										const _state = {};

										_state.private_key = event.target.value;

										this.setState({
											...this.state,
											..._state,
										});
									}}
								/>
								<Button
									onClick={(event) => {
										this.updatePersistedKeys();
									}}
									className="add-token-button d-flex justify-content-center align-items-center float-left text-center">
									<img
										alt=""
										src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8dGl0bGUvPgoKIDxnPgogIDx0aXRsZT5iYWNrZ3JvdW5kPC90aXRsZT4KICA8cmVjdCBmaWxsPSJub25lIiBpZD0iY2FudmFzX2JhY2tncm91bmQiIGhlaWdodD0iNDAyIiB3aWR0aD0iNTgyIiB5PSItMSIgeD0iLTEiLz4KIDwvZz4KIDxnPgogIDx0aXRsZT5MYXllciAxPC90aXRsZT4KICA8cGF0aCBmaWxsPSIjZmZmZmZmIiBpZD0ic3ZnXzEiIGQ9Im0yMS4yNSw1LjkybC00LjM1LC0zLjQ4YTIsMiAwIDAgMCAtMS4yNSwtMC40NGwtMTEuNjUsMGEyLDIgMCAwIDAgLTIsMmwwLDE2YTIsMiAwIDAgMCAyLDJsMTYsMGEyLDIgMCAwIDAgMiwtMmwwLC0xMi41MmEyLDIgMCAwIDAgLTAuNzUsLTEuNTZ6bS01LjI1LC0xLjY0bDAsMi43MmwtOCwwbDAsLTNsNy42NSwwbDAuMzUsMC4yOHptMCwxNS43MmwtOCwwbDAsLTdsOCwwbDAsN3ptMiwwbDAsLThhMSwxIDAgMCAwIC0xLC0xbC0xMCwwYTEsMSAwIDAgMCAtMSwxbDAsOGwtMiwwbDAsLTE2bDIsMGwwLDRhMSwxIDAgMCAwIDEsMWwxMCwwYTEsMSAwIDAgMCAxLC0xbDAsLTIuMTJsMiwxLjZsMCwxMi41MmwtMiwweiIvPgogPC9nPgo8L3N2Zz4="
									/>
								</Button>
							</React.Fragment>
						)}
						{private_key_persisted && (
							<React.Fragment>
								<div className="d-block clearfix">
									<div className="pk-label form-control float-left form-control-lg" disabled size="lg" type="text">
										{account}
									</div>
									<Button
										onClick={(event) => {
											this.updatePersistedKeys(true);
										}}
										className="add-token-button d-flex justify-content-center align-items-center float-left text-center">
										<img
											alt=""
											src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CgogPHRpdGxlLz4KIDxnPgogIDx0aXRsZT5iYWNrZ3JvdW5kPC90aXRsZT4KICA8cmVjdCBmaWxsPSJub25lIiBpZD0iY2FudmFzX2JhY2tncm91bmQiIGhlaWdodD0iNDAyIiB3aWR0aD0iNTgyIiB5PSItMSIgeD0iLTEiLz4KIDwvZz4KIDxnPgogIDx0aXRsZT5MYXllciAxPC90aXRsZT4KICA8cmVjdCBmaWxsPSJub25lIiBpZD0ic3ZnXzEiIHk9IjMiIHg9IjkiIHdpZHRoPSI2IiBoZWlnaHQ9IjIiIGNsYXNzPSJjbHMtMSIvPgogIDxwYXRoIGZpbGw9Im5vbmUiIGlkPSJzdmdfMiIgZD0ibTE4LjA2LDIxbDAuODgsLTE0bC0xMy44OCwwbDAuODgsMTRsMTIuMTIsMHptLTUuMDYsLTExYTEsMSAwIDAgMSAyLDBsMCw4YTEsMSAwIDAgMSAtMiwwbDAsLTh6bS00LDBhMSwxIDAgMCAxIDIsMGwwLDhhMSwxIDAgMCAxIC0yLDBsMCwtOHoiIGNsYXNzPSJjbHMtMSIvPgogIDxwYXRoIGZpbGw9IiNmZmZmZmYiIGlkPSJzdmdfMyIgZD0ibTIsN2wxLjA2LDBsMC45NCwxNS4wNmExLDEgMCAwIDAgMSwwLjk0bDE0LDBhMSwxIDAgMCAwIDEsLTAuOTRsMC45NCwtMTUuMDZsMS4wNiwwYTEsMSAwIDAgMCAwLC0ybC01LDBsMCwtMmEyLDIgMCAwIDAgLTIsLTJsLTYsMGEyLDIgMCAwIDAgLTIsMmwwLDJsLTUsMGExLDEgMCAwIDAgMCwyem03LC00bDYsMGwwLDJsLTYsMGwwLC0yem0tMSw0bDEwLjk0LDBsLTAuODgsMTRsLTEyLjEyLDBsLTAuODgsLTE0bDIuOTQsMHoiLz4KICA8cGF0aCBmaWxsPSIjZmZmZmZmIiBpZD0ic3ZnXzQiIGQ9Im0xMCwxOWExLDEgMCAwIDAgMSwtMWwwLC04YTEsMSAwIDAgMCAtMiwwbDAsOGExLDEgMCAwIDAgMSwxeiIvPgogIDxwYXRoIGZpbGw9IiNmZmZmZmYiIGlkPSJzdmdfNSIgZD0ibTE0LDE5YTEsMSAwIDAgMCAxLC0xbDAsLThhMSwxIDAgMCAwIC0yLDBsMCw4YTEsMSAwIDAgMCAxLDF6Ii8+CiA8L2c+Cjwvc3ZnPg=="
										/>
									</Button>
								</div>
							</React.Fragment>
						)}
					</div>
					<div className="mb-3 d-block clearfix">
						{token_list && (
							<React.Fragment>
								<Dropdown
									onChange={(event, target) => {
										const _state = {};

										const item = R.find(R.propEq("value", target.value))(this.state.options);

										_state.item = item;
										_state.selected_symbol = target.value;

										this.setState(
											{
												...this.state,
												..._state,
											},
											() => {
												this.updatePersistedSelectedSymbol();
											}
										);
									}}
									fluid
									labeled
									selection
									placeholder={selected_label}
									options={options}
									className="token-select form-control form-control-lg float-left icon"
								/>
								<Button
									onClick={(event) => {
										const _state = {};

										_state.token_list = !this.state.token_list;

										this.setState({
											...this.state,
											..._state,
										});
									}}
									className="add-token-button d-flex justify-content-center align-items-center float-left text-center">
									<img
										alt=""
										src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTYuMDAwMDAwMDAwMDAwMDEiIGhlaWdodD0iOTYuMDAwMDAwMDAwMDAwMDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CgogPGc+CiAgPHRpdGxlPmJhY2tncm91bmQ8L3RpdGxlPgogIDxyZWN0IGZpbGw9Im5vbmUiIGlkPSJjYW52YXNfYmFja2dyb3VuZCIgaGVpZ2h0PSI0MDIiIHdpZHRoPSI1ODIiIHk9Ii0xIiB4PSItMSIvPgogPC9nPgogPGc+CiAgPHRpdGxlPkxheWVyIDE8L3RpdGxlPgogIDxwYXRoIGlkPSJzdmdfMyIgZmlsbD0iI2ZmZmZmZiIgZD0ibTU3LDc2YzAsNC40IC0zLjYsOCAtOCw4bC0yLDBjLTQuNCwwIC04LC0zLjYgLTgsLThsMCwtNTZjMCwtNC40IDMuNiwtOCA4LC04bDIsMGM0LjQsMCA4LDMuNiA4LDhsMCw1NnoiLz4KICA8cGF0aCBpZD0ic3ZnXzUiIGZpbGw9IiNmZmZmZmYiIGQ9Im04NCw0OWMwLDQuNCAtMy42LDggLTgsOGwtNTYsMGMtNC40LDAgLTgsLTMuNiAtOCwtOGwwLC0yYzAsLTQuNCAzLjYsLTggOCwtOGw1NiwwYzQuNCwwIDgsMy42IDgsOGwwLDJ6Ii8+CiA8L2c+Cjwvc3ZnPg=="
									/>
								</Button>
							</React.Fragment>
						)}
						{!token_list && (
							<React.Fragment>
								<FormInput
									className="token-input float-left"
									size="lg"
									placeholder="Contract Address"
									type="text"
									onKeyUp={(event) => {
										const _state = {};

										_state.token_input = event.target.value;

										this.setState({
											...this.state,
											..._state,
										});
									}}
									onChange={(event) => {
										const _state = {};

										_state.token_input = event.target.value;

										this.setState({
											...this.state,
											..._state,
										});
									}}
								/>
								<Button
									onClick={(event) => {
										const { token_input } = this.state;

										this.sendTokenApproval(token_input)
											.then((result) => {
												const _state = {};

												_state.token_list = !this.state.token_list;

												this.setState({
													...this.state,
													..._state,
												});
											})
											.catch((error) => {
												console.error(error);

												const _state = {
													activities: [...this.state.activities],
												};

												_state.activities.push({
													message: error,
												});

												this.setState(
													{
														...this.state,
														..._state,
													},
													() => {
														this.updatePersistedActivities();
													}
												);
											});
									}}
									className="add-token-button d-flex justify-content-center align-items-center float-left text-center">
									<img
										alt=""
										src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8dGl0bGUvPgoKIDxnPgogIDx0aXRsZT5iYWNrZ3JvdW5kPC90aXRsZT4KICA8cmVjdCBmaWxsPSJub25lIiBpZD0iY2FudmFzX2JhY2tncm91bmQiIGhlaWdodD0iNDAyIiB3aWR0aD0iNTgyIiB5PSItMSIgeD0iLTEiLz4KIDwvZz4KIDxnPgogIDx0aXRsZT5MYXllciAxPC90aXRsZT4KICA8cGF0aCBmaWxsPSIjZmZmZmZmIiBpZD0ic3ZnXzEiIGQ9Im0yMS4yNSw1LjkybC00LjM1LC0zLjQ4YTIsMiAwIDAgMCAtMS4yNSwtMC40NGwtMTEuNjUsMGEyLDIgMCAwIDAgLTIsMmwwLDE2YTIsMiAwIDAgMCAyLDJsMTYsMGEyLDIgMCAwIDAgMiwtMmwwLC0xMi41MmEyLDIgMCAwIDAgLTAuNzUsLTEuNTZ6bS01LjI1LC0xLjY0bDAsMi43MmwtOCwwbDAsLTNsNy42NSwwbDAuMzUsMC4yOHptMCwxNS43MmwtOCwwbDAsLTdsOCwwbDAsN3ptMiwwbDAsLThhMSwxIDAgMCAwIC0xLC0xbC0xMCwwYTEsMSAwIDAgMCAtMSwxbDAsOGwtMiwwbDAsLTE2bDIsMGwwLDRhMSwxIDAgMCAwIDEsMWwxMCwwYTEsMSAwIDAgMCAxLC0xbDAsLTIuMTJsMiwxLjZsMCwxMi41MmwtMiwweiIvPgogPC9nPgo8L3N2Zz4="
									/>
								</Button>
							</React.Fragment>
						)}
					</div>
					<ListGroup className="mb-3 position-relative">
						<ListGroupItem>Current Price: {this.formatPrice(current, "usd")} (Uniswap)</ListGroupItem>
						<ListGroupItem>24h Price (Low): {this.formatPrice(low, "usd")}</ListGroupItem>
						<ListGroupItem>24h Price (High): {this.formatPrice(high, "usd")}</ListGroupItem>
						<ListGroupItem>Trading Volume: {this.formatPrice(volume, "usd")}</ListGroupItem>
						<ListGroupItem className="d-block d-lg-none d-md-none d-sm-block">
							<div
								onClick={(event) => {
									const { selected_symbol } = this.state;

									this.checkCoingeckoListing(selected_symbol);
								}}
								className="curved-check float-left position-relative">
								<div className="check-text">Click to refresh Curved&trade; price data feeds!</div>
							</div>
							{!approved && (
								<div
									onClick={(event) => {
										this.sendTokenApproval(address);
									}}
									className="curved-approve float-right position-relative">
									<div className="check-text">Approve token for Curved&trade; usage in the future!</div>
								</div>
							)}
							{approved && (
								<div className="curved-approve float-right position-relative">
									<div className="check-text">Token approved for Curved&trade; usage in the future!</div>
								</div>
							)}
						</ListGroupItem>
						<div
							onClick={(event) => {
								const { selected_symbol } = this.state;

								this.checkCoingeckoListing(selected_symbol);
							}}
							className="curved-check d-none d-lg-block d-md-block d-sm-none position-absolute">
							<div className="check-text">
								Click to refresh Curved&trade;
								<br />
								price data feeds!
							</div>
						</div>
						{!approved && (
							<div
								onClick={(event) => {
									const { selected_symbol } = this.state;

									this.sendTokenApproval(selected_symbol);
								}}
								className="curved-approve d-none d-lg-block d-md-block d-sm-none position-absolute">
								<div className="check-text">
									Approve token for Curved&trade;
									<br />
									usage in the future!
								</div>
							</div>
						)}
						{approved && (
							<div
								onClick={(event) => {
									const { selected_symbol } = this.state;

									this.sendTokenApproval(selected_symbol);
								}}
								className="curved-approve d-none d-lg-block d-md-block d-sm-none position-absolute">
								<div className="check-text">
									Token approved for Curved&trade;
									<br />
									usage in the future!
								</div>
							</div>
						)}
					</ListGroup>
				</Col>
			</Row>
		);
	};

	sendEntryOrder = (entry_order) => {
		const _state = {};
		const { orders, private_key } = this.state;

		const startTransaction = (txn_order) => {
			this.getGasPrice()
				.then((result) => {
					if (result === true) {
						const { symbol } = txn_order;
						const { gas_limits, tokens } = this.state;

						const order_gas = R.pathOr(null, ["gas"], txn_order);
						const txn_gas = typeof order_gas === "string" ? R.pathOr(null, [order_gas], gas_limits) : order_gas;
						const token = R.pathOr(null, [symbol], tokens);
						const token_decimals = R.pathOr(1, ["decimals"], token);
						const token_decimals_uint = Math.pow(10, token_decimals);

						const pk = new Buffer(private_key, "hex");
						const address = this.getAddressFromPrivateKey(pk);
						const hexified_pk = "0x" + private_key.toString("hex");
						const value = 1e18 * +R.pathOr(0, ["spend_amount"], txn_order);

						const uniswapV2RouterContract = web3.eth.contract(abiUniswapV2Router);
						const uniswapV2RouterInstance = uniswapV2RouterContract.at(uniswapV2Router);

						const amount1Out = txn_order.position_entry_amount * token_decimals_uint;
						const to = address;
						const deadline = new Date();

						deadline.setMinutes(deadline.getMinutes() + 20);

						const swapExactETHForTokens = uniswapV2RouterInstance.swapExactETHForTokens.getData(amount1Out, [wethToken, token.address], to, deadline.getTime());

						web3.eth.getTransactionCount(address, (error, nonce) => {
							const raw = {
								...txn_gas,
								nonce: nonce,
								to: uniswapV2Router,
								from: address,
								value: web3.toHex(value),
								data: swapExactETHForTokens,
							};

							web3.eth.sendRawTransaction(sign(raw, hexified_pk), (error, swap_result) => {
								if (!error) {
									console.log("[TXN] - swapExactETHForTokens", swap_result);

									const getReceipt = (txn) => {
										return new Promise((resolve, reject) => {
											web3.eth.getTransactionReceipt(txn, (error, result) => {
												if (error) {
													reject(error);
												} else {
													resolve(result);
												}
											});
										});
									};

									const purchasedTokenContract = web3.eth.contract(token.abi);
									const purchasedTokenContractInstance = purchasedTokenContract.at(token.address);
									const event = purchasedTokenContractInstance.Transfer({
										transactionHash: swap_result,
									});

									const swapObservable = timer(0, 2000);

									swapObservable
										.pipe(concatMap(() => from(getReceipt(swap_result)).pipe(map((response) => response))))
										.pipe(filter((result) => typeof result === "object" && result !== null))
										.pipe(take(1))
										.subscribe((result) => {
											const { status } = result;

											if (web3.toDecimal(status) === 1) {
												event.watch((error, result) => {
													if (!error) {
														const { transactionHash } = result;

														if (transactionHash === swap_result) {
															const _state = {};

															_state.orders = orders.map((order, index) => {
																if (order.id === txn_order.id && order.type === "entry") {
																	order.order = "ordered";
																}

																return order;
															});

															this.setState(
																{
																	...this.state,
																	..._state,
																},
																() => {
																	this.updatePersistedOrders();
																}
															);

															event.stopWatching();
														}
													} else {
														console.error(error);

														const _state = {
															activities: [...this.state.activities],
														};

														_state.activities.push({
															id: entry_order.id,
															message: error,
															status: status,
															txn: swap_result,
														});

														this.setState(
															{
																...this.state,
																..._state,
															},
															() => {
																this.updatePersistedActivities();
															}
														);
													}
												});
											} else if (web3.toDecimal(status) === 0) {
												console.error("[ERROR] - Transaction failed.");

												const _state = {
													activities: [...this.state.activities],
												};

												_state.activities.push({
													id: entry_order.id,
													message: "swapExactETHForTokens - Transaction failed.",
													status: status,
													txn: swap_result,
												});

												this.setState(
													{
														...this.state,
														..._state,
													},
													() => {
														this.updatePersistedActivities();
													}
												);
											}
										});
								} else {
									console.error(error);

									const _state = {
										activities: [...this.state.activities],
									};

									_state.activities.push({
										id: entry_order.id,
										message: error,
									});

									this.setState(
										{
											...this.state,
											..._state,
										},
										() => {
											this.updatePersistedActivities();
										}
									);
								}
							});
						});
					}
				})
				.catch((error) => {
					console.error(error);

					const _state = {
						activities: [...this.state.activities],
					};

					_state.activities.push({
						id: entry_order.id,
						message: error,
					});

					this.setState(
						{
							...this.state,
							..._state,
						},
						() => {
							this.updatePersistedActivities();
						}
					);
				});
		};

		if (private_key !== null) {
			_state.orders = orders.map((order, index) => {
				if (order.id === entry_order.id && order.type === "entry") {
					order.order = "placed";
				}

				return order;
			});

			this.setState(
				{
					...this.state,
					..._state,
				},
				() => {
					this.updatePersistedOrders();
					startTransaction(entry_order);
				}
			);
		}
	};

	sendExitOrder = (exit_order, params) => {
		const _state = {};
		const { orders, private_key } = this.state;

		const startTransaction = (txn_order) => {
			this.getGasPrice()
				.then((result) => {
					if (result === true) {
						const { symbol } = txn_order;
						const { gas_limits, tokens } = this.state;

						const order_gas = R.pathOr(null, ["gas"], txn_order);
						const txn_gas = typeof order_gas === "string" ? R.pathOr(null, [order_gas], gas_limits) : order_gas;
						const token = R.pathOr(null, [symbol], tokens);

						const pk = new Buffer(private_key, "hex");
						const address = this.getAddressFromPrivateKey(pk);
						const hexified_pk = "0x" + private_key.toString("hex");

						const uniswapV2RouterContract = web3.eth.contract(abiUniswapV2Router);
						const uniswapV2RouterInstance = uniswapV2RouterContract.at(uniswapV2Router);

						const { amt_in } = params;
						const amt_out = 0; // ensures successful transaction
						const to = address;
						const deadline = new Date();
						deadline.setMinutes(deadline.getMinutes() + 20);

						const swapExactTokensForETH = uniswapV2RouterInstance.swapExactTokensForETH.getData(amt_in, amt_out, [token.address, wethToken], to, deadline.getTime());

						web3.eth.getTransactionCount(address, (error, nonce) => {
							const raw = {
								...txn_gas,
								nonce: nonce,
								to: uniswapV2Router,
								from: address,
								value: web3.toHex(0),
								data: swapExactTokensForETH,
							};

							web3.eth.sendRawTransaction(sign(raw, hexified_pk), (swap_error, swap_result) => {
								if (!swap_error) {
									console.log("[TXN] - swapExactTokensForETH", swap_result);

									const getReceipt = (txn) => {
										return new Promise((resolve, reject) => {
											web3.eth.getTransactionReceipt(txn, (error, result) => {
												if (error) {
													reject(error);
												} else {
													resolve(result);
												}
											});
										});
									};

									const purchasedTokenContract = web3.eth.contract(abiUniswapV2Pair);
									const purchasedTokenContractInstance = purchasedTokenContract.at(token.exchangeV2);
									const event = purchasedTokenContractInstance.Swap({
										transactionHash: swap_result,
									});

									const swapObservable = timer(0, 2000);

									swapObservable
										.pipe(concatMap(() => from(getReceipt(swap_result)).pipe(map((response) => response))))
										.pipe(filter((result) => typeof result === "object" && result !== null))
										.pipe(take(1))
										.subscribe((result) => {
											const { status } = result;

											if (web3.toDecimal(status) === 1) {
												event.watch((error, result) => {
													if (!error) {
														const { transactionHash } = result;

														if (transactionHash === swap_result) {
															const _state = {};

															_state.orders = orders.map((order, index) => {
																if (order.id === txn_order.id && order.type === "exit") {
																	order.order = "exited";
																}

																return order;
															});

															this.setState(
																{
																	...this.state,
																	..._state,
																},
																() => {
																	this.updatePersistedOrders();
																}
															);

															event.stopWatching();
														}
													} else {
														console.error(error);

														const _state = {
															activities: [...this.state.activities],
														};

														_state.activities.push({
															id: exit_order.id,
															message: error,
															status: status,
															txn: swap_result,
														});

														this.setState(
															{
																...this.state,
																..._state,
															},
															() => {
																this.updatePersistedActivities();
															}
														);
													}
												});
											} else if (web3.toDecimal(status) === 0) {
												console.error("[ERROR] - Transaction failed.");

												const _state = {
													activities: [...this.state.activities],
												};

												_state.activities.push({
													id: exit_order.id,
													message: "swapExactTokensForETH - Transaction failed.",
													status: status,
													txn: swap_result,
												});

												this.setState(
													{
														...this.state,
														..._state,
													},
													() => {
														this.updatePersistedActivities();
													}
												);
											}
										});
								} else {
									console.error(error);

									const _state = {
										activities: [...this.state.activities],
									};

									_state.activities.push({
										id: exit_order.id,
										message: error,
									});

									this.setState(
										{
											...this.state,
											..._state,
										},
										() => {
											this.updatePersistedActivities();
										}
									);
								}
							});
						});
					}
				})
				.catch((error) => {
					console.error(error);

					const _state = {
						activities: [...this.state.activities],
					};

					_state.activities.push({
						id: exit_order.id,
						message: error,
					});

					this.setState(
						{
							...this.state,
							..._state,
						},
						() => {
							this.updatePersistedActivities();
						}
					);
				});
		};

		if (private_key !== null) {
			_state.orders = orders.map((order, index) => {
				if (order.id === exit_order.id && order.type === "exit") {
					order.order = "exiting";
				}

				return order;
			});

			this.setState(
				{
					...this.state,
					..._state,
				},
				() => {
					this.updatePersistedOrders();
					startTransaction(exit_order);
				}
			);
		}
	};

	sendTokenApproval = (token) => {
		return new Promise((resolve, reject) => {
			if (token) {
				this.getGasPrice()
					.then((result) => {
						if (result === true) {
							const { gas_limits, private_key } = this.state;
							const txn_gas = R.pathOr(null, ["fast"], gas_limits);

							if (abiERC20Token) {
								const contract = web3.eth.contract(abiERC20Token);
								const contractInstance = contract.at(token);
								const approve = contractInstance.approve.getData(uniswapV2Router, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
								const pk = new Buffer(private_key, "hex");
								const address = this.getAddressFromPrivateKey(pk);
								const hexified_pk = "0x" + private_key.toString("hex");

								web3.eth.getTransactionCount(address, (count_error, nonce) => {
									if (!count_error) {
										const raw = {
											...txn_gas,
											nonce: nonce,
											to: token,
											from: address,
											value: web3.toHex(0),
											data: approve,
										};

										web3.eth.sendRawTransaction(sign(raw, hexified_pk), (approve_error, approve_result) => {
											if (!approve_error) {
												console.log("[TXN] - approve", approve_result);

												this.updateSelectedToken(token, true)
													.then((update_result) => {
														resolve(true);
													})
													.catch((update_error) => {
														reject(update_error);
													});
											} else {
												reject(approve_error);
											}
										});
									} else {
										reject(count_error);
									}
								});
							} else {
								reject("[ERROR] - Unable to parse ABI.");
							}
						} else {
							reject("[ERROR] - Unable to retrieve gas price.");
						}
					})
					.catch((error) => {
						reject(error);
					});
			} else {
				reject("[ERROR] - Invalid contract address.");
			}
		});
	};

	setPriceData = (id) => {
		const { base_unit, crypto_price, options, tokens } = this.state;
		// const token = R.pathOr(R.find(R.propEq("address", id))(options), [id], tokens);
		const token = R.pathOr(null, [id], tokens);

		if (token) {
			const exchangeV2Contract = web3.eth.contract(abiUniswapV2Exchange);
			const exchangeV2Instance = exchangeV2Contract.at(token.exchangeV2);

			exchangeV2Instance.decimals((error, result) => {
				const decimals = parseInt(result ? result.toString() : 0, 10);
				const decimals_uint = Math.pow(10, decimals);
				const spend_amount = base_unit * decimals_uint;
				const token_decimals = R.pathOr(1, ["decimals"], token);
				const token_decimals_uint = Math.pow(10, token_decimals);

				exchangeV2Instance.getReserves((error, result) => {
					const outputReserve = +R.pathOr(0, [0], result).toString(10);
					const inputReserve = +R.pathOr(0, [1], result).toString(10);
					const token0 = outputReserve / decimals_uint;
					const token1 = inputReserve / token_decimals_uint;
					const inputAmountWithFee = spend_amount * 0.997;
					const numerator = inputAmountWithFee * outputReserve;
					const denominator = inputReserve + inputAmountWithFee;
					const proposed_order = numerator / denominator / token_decimals_uint;

					if (token.coingecko) {
						const market_url = "https://api.coingecko.com/api/v3/coins/" + id + "/market_chart?vs_currency=USD&days=1";
						const price_params = "&vs_currencies=USD&include_market_cap=true&include_24hr_vol=true&include_24hr_change=true&include_last_updated_at=true";
						const price_url = "https://api.coingecko.com/api/v3/simple/price?ids=" + id + price_params;

						axios({
							method: "GET",
							headers: { "content-type": "application/json" },
							url: price_url,
						})
							.then((price_result) => {
								axios({
									method: "GET",
									headers: { "content-type": "application/json" },
									url: market_url,
								})
									.then((market_result) => {
										const _state = {};
										const prices = R.pathOr([], ["data", "prices"], market_result);

										let low = prices[0][1];
										let high = 0;
										let current = R.pathOr(0, ["data", id, "usd"], price_result);

										prices.forEach((price, index) => {
											low = price[1] < low ? price[1] : low;
											high = price[1] > high ? price[1] : high;
										});

										const price = crypto_price > 0 ? (crypto_price / proposed_order) * base_unit : current;
										const rate = crypto_price > 0 ? (token0 / token1) * crypto_price : current;

										if (price === 0) {
											current = rate;
										} else if (deviation(price, current) > 5) {
											current = rate;
										} else {
											current = price;
										}

										_state.price_data = {
											current: current,
											high: high,
											low: low,
											volume: R.pathOr(0, ["data", id, "usd_24h_vol"], price_result),
										};

										this.setState({
											...this.state,
											..._state,
										});
									})
									.catch((error) => {
										console.error(error);
									});
							})
							.catch((error) => {
								console.error(error);
							});
					} else {
						const _state = {};

						let	current;

						const price =  crypto_price > 0 ? (crypto_price / proposed_order) * base_unit : current;
						const rate = crypto_price > 0 ? (token0 / token1) * crypto_price : current;

						if (price === 0) {
							current = rate;
						} else if (deviation(price, current) > 5) {
							current = rate;
						} else {
							current = price;
						}

						_state.price_data = {
							current: current,
							high: current,
							low: 0,
							volume: 0,
						};

						this.setState({
							...this.state,
							..._state,
						});
					}
				});
			});
		} else {
			this.updateSelectedToken(id)
				.then((symbol) => {
					const _state = {};

					_state.selected_symbol = symbol;

					this.setState(
						{
							...this.state,
							..._state,
						},
						() => {
							this.updatePersistedSelectedSymbol();
						}
					);
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	setTokenBalance = (id) => {
		const { account, tokens } = this.state;
		const token = R.pathOr(null, [id], tokens);

		if (token) {
			if (abiERC20Token) {
				const contract = web3.eth.contract(abiERC20Token);
				const contractInstance = contract.at(token.address);

				contractInstance.decimals((decimals_error, result) => {
					if (!decimals_error) {
						const decimals = parseInt(result ? result.toString() : 0, 10);
						const decimals_uint = Math.pow(10, decimals);

						contractInstance.balanceOf(account, (balance_error, result) => {
							if (!balance_error) {
								const _state = {};

								_state.selected_symbol_balance = parseInt(result ? result.toString() : 0, 10) / decimals_uint;

								this.setState({
									...this.state,
									..._state,
								});
							} else {
								console.error(balance_error);
							}
						});
					} else {
						console.error(decimals_error);
					}
				});
			} else {
				console.error("[ERROR] - Unable to parse ABI.");
			}
		} else {
			this.updateSelectedToken(id)
				.then((symbol) => {
					const _state = {};

					_state.selected_symbol = symbol;

					this.setState(
						{
							...this.state,
							..._state,
						},
						() => {
							this.updatePersistedSelectedSymbol();
						}
					);
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	toggleLabelEntry = () => {
		return true;

		// const { toggle_order_entry_label } = this.state;
		// const _state = {};
		//
		// _state.toggle_order_entry_label = !toggle_order_entry_label;
		//
		// this.setState({
		// 	...this.state,
		// 	..._state,
		// });
	};

	toggleLabelExit = () => {
		return true;

		// const { toggle_order_exit_label } = this.state;
		// const _state = {};
		//
		// _state.toggle_order_exit_label = !toggle_order_exit_label;
		//
		// this.setState({
		// 	...this.state,
		// 	..._state,
		// });
	};

	updateEntryAmount = (event) => {
		const { balance, currency, crypto_price, slider_position_entry_value } = this.state;

		const _state = {};

		_state.slider_order_entry_value = +event[0];
		_state.order_entry_amount = (balance * Math.floor(+event[0])) / 100;

		switch (currency) {
			case "usd":
				_state.position_entry_amount = slider_position_entry_value > 0 ? (_state.order_entry_amount * crypto_price) / slider_position_entry_value : 0;
				break;
			default:
				_state.position_entry_amount = 0;
				break;
		}

		this.setState({
			...this.state,
			..._state,
		});

		return true;
	};

	updateEntryPrice = (event) => {
		const { currency, crypto_price, order_entry_amount } = this.state;
		const _state = {};

		_state.slider_position_entry_value = +event[0];

		switch (currency) {
			case "usd":
				_state.position_entry_amount = _state.slider_position_entry_value > 0 ? (order_entry_amount * crypto_price) / _state.slider_position_entry_value : 0;
				break;
			default:
				_state.position_entry_amount = 0;
				break;
		}

		this.setState({
			...this.state,
			..._state,
		});

		return true;
	};

	updateExitAmount = (event) => {
		const { selected_symbol_balance } = this.state;

		const _state = {};

		_state.slider_order_exit_value = +event[0];
		_state.order_exit_amount = (selected_symbol_balance * Math.floor(+event[0])) / 100;

		this.setState({
			...this.state,
			..._state,
		});

		return true;
	};

	updateExitPrice = (event) => {
		const _state = {};

		_state.slider_position_exit_value = +event[0];

		this.setState({
			...this.state,
			..._state,
		});

		return true;
	};

	updateOrderPercentage = (event) => {
		const _state = {};

		_state.slider_order_percentage_value = +event[0];

		this.setState({
			...this.state,
			..._state,
		});

		return true;
	};

	updateProfitPercentage = (event) => {
		const _state = {};

		_state.slider_profit_percentage_value = +event[0];

		this.setState({
			...this.state,
			..._state,
		});

		return true;
	};

	updatePersistedActivities = () => {
		const { activities } = this.state;

		localStorage.setItem("activities", JSON.stringify(activities));
	};

	updatePersistedKeys = (reset) => {
		const _state = {};

		if (reset) {
			localStorage.setItem("pkm", "");

			_state.private_key = "";
			_state.private_key_persisted = false;
		} else {
			const { private_key } = this.state;

			localStorage.setItem("pkm", private_key);

			_state.private_key_persisted = true;
		}

		this.setState(
			{
				...this.state,
				..._state,
			},
			() => {
				this.refreshSignedValues();
			}
		);
	};

	updatePersistedOptions = () => {
		const { options } = this.state;

		localStorage.setItem("options", JSON.stringify(options));
	};

	updatePersistedOrders = () => {
		const { orders } = this.state;

		localStorage.setItem("orders", JSON.stringify(orders));
	};

	updatePersistedSelectedSymbol = () => {
		const { selected_symbol } = this.state;

		localStorage.setItem("selected_symbol", selected_symbol);
	};

	updatePersistedTokens = () => {
		const { tokens } = this.state;

		localStorage.setItem("tokens", JSON.stringify(tokens));
	};

	updateSelectedToken = (token, approval) => {
		return new Promise((resolve, reject) => {
			const token_data_url = "https://api.coingecko.com/api/v3/coins/ethereum/contract/" + token;

			if (abiERC20Token) {
				const contract = web3.eth.contract(abiERC20Token);
				const contractInstance = contract.at(token);

				axios({
					method: "GET",
					headers: { "content-type": "application/json" },
					url: token_data_url,
				})
					.then((token_data_result) => {
						const token_data_error = R.pathOr(null, ["data", "error"], token_data_result);

						if (!token_data_error) {
							const id = R.pathOr(null, ["data", "id"], token_data_result);
							const name = R.pathOr(null, ["data", "name"], token_data_result);
							const symbol = R.pathOr(null, ["data", "symbol"], token_data_result);
							const logo = R.pathOr(null, ["data", "image", "large"], token_data_result);

							contractInstance.decimals((decimals_error, result) => {
								if (!decimals_error) {
									const decimals = parseInt(result ? result.toString() : 0, 10);

									const factory_abi_url = "https://api.etherscan.io/api?module=contract&action=getabi&address=" + uniswapV2Factory + "&apikey=EUYY17VTVYZQ8S4SMJ7KU741Q97R63RUBF";

									axios({
										method: "GET",
										headers: { "content-type": "application/json" },
										url: factory_abi_url,
									})
										.then((factory_abi_result) => {
											const factory_abi = JSON.parse(R.pathOr(null, ["data", "result"], factory_abi_result));

											const uniswapV2FactoryContract = web3.eth.contract(factory_abi);
											const uniswapV2FactoryInstance = uniswapV2FactoryContract.at(uniswapV2Factory);

											uniswapV2FactoryInstance.getPair(token, wethToken, (error, pair_result) => {
												if (!error) {
													const _state = {};
													const { tokens } = this.state;

													_state.tokens = tokens;
													_state.tokens[id] = {
														abi: abiERC20Token,
														address: token,
														coingecko: true,
														decimals: decimals,
														exchangeV1: null,
														exchangeV2: pair_result,
														label: name,
														logo: logo,
														symbol: id,
														ticker: symbol.toUpperCase(),
													};

													if (approval) {
														_state.tokens[id].approved = true;
													}

													this.setState(
														{
															...this.state,
															..._state,
														},
														async () => {
															this.updatePersistedTokens();

															this.getTokenOptions()
																.then((result) => {
																	const _state = {};

																	_state.options = result;

																	this.setState(
																		{
																			...this.state,
																			..._state,
																		},
																		() => {
																			this.updatePersistedOptions();
																		}
																	);
																})
																.catch((error) => {
																	console.error(error);
																});

															resolve(id);
														}
													);
												} else {
													reject(error);
												}
											});
										})
										.catch((error) => {
											reject(error);
										});
								} else {
									reject(decimals_error);
								}
							});
						}
					})
					.catch((error) => {
						if (error.response.status === 404) {
							const id = token;
							let name;
							let symbol;
							let logo;

							contractInstance.name((name_error, result) => {
								if (!name_error) {
									name = result;
								} else {
									reject(name_error);
								}
							});

							contractInstance.symbol((symbol_error, result) => {
								if (!symbol_error) {
									symbol = result;
								} else {
									reject(symbol_error);
								}

								if (!symbol) {
									symbol = "";
								}
							});

							contractInstance.decimals((decimals_error, result) => {
								if (!decimals_error) {
									const decimals = parseInt(result ? result.toString() : 0, 10);

									const factory_abi_url = "https://api.etherscan.io/api?module=contract&action=getabi&address=" + uniswapV2Factory + "&apikey=EUYY17VTVYZQ8S4SMJ7KU741Q97R63RUBF";

									axios({
										method: "GET",
										headers: { "content-type": "application/json" },
										url: factory_abi_url,
									})
										.then((factory_abi_result) => {
											const factory_abi = JSON.parse(R.pathOr(null, ["data", "result"], factory_abi_result));

											const uniswapV2FactoryContract = web3.eth.contract(factory_abi);
											const uniswapV2FactoryInstance = uniswapV2FactoryContract.at(uniswapV2Factory);

											uniswapV2FactoryInstance.getPair(token, wethToken, (error, pair_result) => {
												if (!error) {
													const _state = {};
													const { tokens } = this.state;

													_state.tokens = tokens;
													_state.tokens[id] = {
														abi: abiERC20Token,
														address: token,
														coingecko: false,
														decimals: decimals,
														exchangeV1: null,
														exchangeV2: pair_result,
														label: name,
														logo: logo,
														symbol: id,
														ticker: symbol.toUpperCase(),
													};

													if (approval) {
														_state.tokens[id].approved = true;
													}

													this.setState(
														{
															...this.state,
															..._state,
														},
														() => {
															this.updatePersistedTokens();
															resolve(true);
														}
													);
												} else {
													reject(error);
												}
											});
										})
										.catch((error) => {
											reject(error);
										});
								} else {
									reject(decimals_error);
								}
							});
						} else {
							reject(error);
						}
					});
			}
		});
	};

	render() {
		const { account, orders, tokens } = this.state;

		return (
			<Container fluid>
				<Row>
					<Col className="main-content p-0" lg="10" md="9" sm="12" tag="main">
						<NavigationBar title="curved" />
						<div className="content-main m-4 pb-4">
							{this.renderTokenPanel()}
							{this.renderOrderPanel()}
							{orders.length > 0 && this.renderSeparator()}
							{/*{orders.length > 0 && this.renderOrderFilter()}*/}
							{orders.length > 0 && this.renderOpenOrders()}
							{/*<Row>{this.renderSelectedChart()}</Row>*/}
						</div>
						<FooterBar title="curved" activities={this.state.activities} />
					</Col>
					<SideBar title="curved" address={account} tokens={tokens} sendTokenApproval={this.sendTokenApproval} orderModal={this.handleModal} />
				</Row>
			</Container>
		);
	}
}

render(<App />, document.getElementById("root"));
